import React, {useState} from 'react';
import { ChevronUp } from 'react-bootstrap-icons';

export default function ScrollButton() {
    const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

    return (
        <div className='scrollToTop' style={{display: visible ? 'inline' : 'none'}}>
            <ChevronUp onClick={scrollToTop} color='white' size='1.25rem' />
        </div>
    )
}
