import React from "react";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StyledHero from "../components/StyledHero";
import Banner from "../components/Banner";
import HowItWorks from "../components/HowItWorks";
import FaqAccordion from "../components/FaqAccordion";
import Subscribe from "../components/Subscribe";
import RequestCallback from "../components/RequestCallback";

import LeadershipImg from "../images/leadership-banner.jpg";

export default function Leadership({
  isSWMC,
  setLoanPurpose,
  setModalShow,
  setOfferLoanType,
}) {
  return (
    <div>
      <Helmet>
        <title>Leadership | Sun West</title>
        <meta
          name="description"
          content="Sun West's vision is to set the industry standard for homeownership by “taking ownership” of the entire process."
        />
      </Helmet>
      <StyledHero img={LeadershipImg} bgPosition="bottom center">
        <Banner title="Leadership" headerFooter={true}
          isSWMC={isSWMC}
          setLoanPurpose={setLoanPurpose}
          setModalShow={setModalShow}
          setOfferLoanType={setOfferLoanType}></Banner>
      </StyledHero>

      <Container>
        <Row>
          <Col id="leadership-wrapper" className="pt-5">
              <h2>A Company Of Integrity</h2>
              <p>
                Sun West is a full-service and forward-thinking professional
                mortgage lender licensed to provide residential mortgages in 48
                states, District of Columbia, Puerto Rico, and the US Virgin
                Islands. Sun West&nbsp;has been built upon the foundation of
                integrity, industry knowledge, and unparalleled service.
              </p>
              <p>&nbsp;</p>
              <p>
                Our vision is to set the industry standard for homeownership by
                “taking ownership” of the entire process. From our product
                offerings to on-time closings, and everything in between, we are
                committed to helping families and individuals achieve the dream
                of homeownership.
              </p>
              <p>&nbsp;</p>
              <p>
                Our diverse team of experienced executives and associates are
                dedicated to the success of Sun West by providing the
                characteristic of a smaller organization with personalized
                service. We are focused on providing innovative tools and
                support to our mortgage professionals, as we truly value our
                relationship with them and their relationship with the referral
                partners that they work with.
              </p>
              <p>&nbsp;</p>
              <h2>What Sets Us Apart</h2>
              <ul>
                <li>
                  Competitive pricing with a wide array of products and programs
                </li>
                <li>
                  Full service approach to enable you to compete for business
                  and close efficiently
                </li>
                <li>A direct lender</li>
                <li>Create long-term relationships - we service our loans</li>
                <li>A commitment to closing every loan on time</li>
                <li>
                  Experienced mortgage executives with a commitment to your
                  success
                </li>
              </ul>
              <p>
                We are committed to our core values of people, experience,
                product,&nbsp;and technology Sun West was founded in 1980 with
                the perspective of “Clients First” and the desire to make the
                mortgage process stress free for prospective homeowners. Since
                then, Sun West services several multi-billion dollar loan
                portfolio and is licensed to lend in 50 states,&nbsp;District of
                Columbia, Puerto Rico and the US Virgin Islands. Our nearly 40
                years of experience has been passed down to everyone here at Sun
                West through outstanding leadership and loan capabilities.
              </p>
          </Col>
        </Row>
      </Container>
      <div className="my-5 pb-2 px-0">
        <HowItWorks />
      </div>
      <FaqAccordion />
      <Col sm={12} className="mt-5 pt-5 px-0">
        <RequestCallback className="mt-5" />
      </Col>
      <Col sm={12}>
        <Subscribe />
      </Col>
    </div>
  );
}
