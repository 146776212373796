import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import 'react-phone-input-2/lib/style.css'
import '../css/callModule.css'
import styled from 'styled-components'

//  Custom CSS
const treasury = {
    opacity: 0,
    position: "absolute",
    width: "0px",
  };
  // End of Custom CSS

function trim(theStr) {
	return theStr.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
}

function getNumbers(inVal, isDouble, removeCharsFromPrefix) {		
    var retVal = "";
    var okChars = "0123456789";
    var tmpVal = trim(inVal);
    var curChar = "";
    var isDotFound = false;
    
    if (tmpVal !== "") {
        for (var i = 0; i < tmpVal.length; i++) {
            curChar = tmpVal.charAt(i);
            
            if (okChars.indexOf(curChar) !==-1) {					
                if (!isDotFound && curChar === "." && isDouble) {
                    isDotFound = true;
                    retVal = retVal + ".";
                }
                
                if (retVal === "" && removeCharsFromPrefix.indexOf(curChar) !== -1) {
                    //Do nothing
                } else {
                    retVal += curChar;
                }
            }
        }
    }
    
    return retVal;
}


export default function Call(props) {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userPhone, setUserPhone] = useState("");
    const [email, setEmail] = useState("");
    const [clientIP, setClientIP] = useState("");
    const [propAddressState, setPropAddressState] = useState("");
    const [statesOptions, setStatesOptions] = useState("");
    const [userName, setUserName] = useState("");
    const [showSent, setShowSent] = useState(false);
    const [showFail, setShowFail] = useState(false);
    const [consent, setConsent] = useState(true);
    const [honeyPot, setHoneyPot] = useState(false);

    const CallBackAPI = process.env.REACT_APP_CALLBACK_API;

    const handleTelephoneNumber = evt => {
        //Save Phone number in local storage for Apply
       
        var tmpVal = trim(getNumbers(evt.currentTarget.value, false, "01"));
        var FirstThreeDigit= tmpVal.substring(0, 3);
        var FirstSixDigit= tmpVal.substring(0, 6);

        /* Below Pattern will check numbers between below ranges
        456, 555, 900,
        100, 101, ..., 199
        211, 311, ..., 911  ***811 removed
        370, 371, ..., 379
        960, 961, ..., 969
        800, 801, ..., 855  ***removed
        866, 877, 888, 800855
        */
        var patt =/^(1[0-9][0-9])|([2-7]11)|(911)|(37[0-9])|(96[0-9])|(456)|(555)|(866)|(877)|(888)|(900)$/;
        var patt2 = /^800855$/;
        if(patt.test(FirstThreeDigit)) {
            evt.currentTarget.value="";
            return;
        } else if(patt2.test(FirstSixDigit)) {
            evt.currentTarget.value="";
            return;
        }

        var curChar = "";
        
        var areaCode = "";
        var firstThree = "";
        var lastFour = "";
        var extension = "";
        
        if (tmpVal !=="") {
            for (var i = 0; i < tmpVal.length; i++) {
                curChar = tmpVal.charAt(i);
                
                if (i < 3) areaCode += curChar;
                if (i < 6 && i > 2) firstThree += curChar;
                if (i < 10 && i > 5) lastFour += curChar;
                if (i > 9) extension += curChar;
            }
        }
        
        var retVal = "";
        
		/* 6/13/2020 - Dennis
			Do not put the parentesis if we only have the first three digits of the phone number.
			Once we are past that then start formatting the phone number. This was the main issue
			why the phone number couldn't be blanked out in it's entirety.
		*/
        if (areaCode !=="" && firstThree === "")
			retVal += areaCode;
		
        else if (areaCode !=="" && firstThree !== "")
			retVal += "(" + areaCode + ")";
				
        if (firstThree !=="")
			retVal += " " + firstThree;
        
        if (lastFour !=="")
			retVal += "-" + lastFour;		
        
        if (extension !=="")
            retVal += " x" + extension;
        else            
            setUserPhone(retVal);
    };

    function refreshPage() {
        window.location.reload(false);
    }


    const requestCallBack  = event => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setLoading(true);
            // Check the honeyPot before submit the form, to prevent bots.
            if(!honeyPot) {
                axios.post(CallBackAPI, {"contactOption": "callBack", "fullName": userName, "userPhone": userPhone, "email": email, "userState": propAddressState, consent: consent, honeyPot: honeyPot},{
                    headers: {'Content-Type': 'application/json'}
                })
                .then(() => {
                    setShowSent(true);
                    setLoading(false);
                    setValidated(false);
                })
                .catch(() => {
                    setLoading(false);
                    setShowFail(true);
                });
            } else {
                setShowFail(true);
            }
        }
        setValidated(true);
    }


    useEffect(() => {
        // Get state from public endpoint
        axios
          .post(
            process.env.REACT_APP_SUNSOFT_RESTSERVER_STATE_LIST,
            { companyCode: "SUNWST000", domain: props.isSWMC ? "ny.swmc.com" : "swmcretail.com" },
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((response) => {
            // Set the dropdown state list option.
            setStatesOptions(
              response.data.map((item, index) => (
                <option key={index} value={item.code}>
                  {item.name}
                </option>
              ))
            );
          });
    
        axios
          .get("https://api.ipify.org", { timeout: 2000 })
          .then((response) => {
            let userIP = response.data;
    
            if (response.data) {
              setClientIP(response.data);
    
              axios
                .post(
                  process.env.REACT_APP_MAXMIND,
                  { ip: clientIP || userIP },
                  {
                    headers: { "Content-Type": "application/json" },
                  },
                  { timeout: 5000 }
                )
                .then((response) => {
                  if (
                    response.data !== "IP Not Available" &&
                    response.data !== "Invalid Country"
                  ) {
                    let zipcode = response.data.zipcode;

                    if (zipcode) {
                      axios
                        .get(
                          process.env.REACT_APP_SUNSOFT_RESTSERVER_ZIPCODE +
                            zipcode,
                          { timeout: 5000 },
                          {
                            headers: { "Content-Type": "application/json" },
                          }
                        )
                        .then((response) => {
                            setPropAddressState(response.data.state);
                        });
                    }
                  } else {
                    console.log(response.data); // Show Error message from lambda function
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });

    }, []);


    return (
        <StyleWrapper>
            <Container className="pt-3 callmebackContainer">
              <Row className="d-flex align-items-center justify-content-center h-100">
                <Col lg={12}>
                  <Form id="smsForm" noValidate validated={validated} onSubmit={requestCallBack}>
                    <Row style={treasury}>
                      <Form.Group as={Col} sm={5} controlId="quote_name">
                        <strong>
                          <Form.Label>* Get Quote Name: </Form.Label>
                        </strong>
                        <Form.Control
                          autoComplete="new-password"
                          type="password"
                          minLength="2"
                          maxLength="12"
                          data-error="Please fill out this field correctly."
                          onChange={() => {
                            setHoneyPot(true);
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={5} controlId="quote_code">
                        <strong>
                          <Form.Label>* Get Quote Code: </Form.Label>
                        </strong>
                        <Form.Control
                          autoComplete="new-password"
                          type="password"
                          pattern="^[0-9a-zA-Z]{4}$"
                          maxLength="4"
                          data-error="Please fill out this field correctly."
                          onChange={() => {
                            setHoneyPot(true);
                          }}
                        />
                      </Form.Group>
                    </Row>
                      <Form.Group controlId="userName">
                          <Form.Label className="sr-only">Name</Form.Label>
                          <Form.Control type="text" value={userName} onChange={e => setUserName(e.target.value)} placeholder="Your Name*" required />
                      </Form.Group>
                      <Form.Group>
                          <Form.Label className="sr-only">Contact Phone</Form.Label>
                          <Form.Control
                              value={userPhone}
                              onChange={handleTelephoneNumber}
                              required
                              minLength={14}
                              maxLength={14}
                              pattern="^\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})[ ]*(( |x){1}[ ]?([0-9]){1,7}){0,1}$"
                              placeholder="Your Phone Number*"
                              />
                      </Form.Group>
                      <Form.Group>
                          <Form.Control
                              type="text"
                              placeholder="Your Email"
                              onChange={(e) => setEmail(e.target.value)}
                              pattern="^([A-Za-z0-9_\-\.])+@([A-Za-z0-9_\-])+.\.([A-Za-z]{2,4})$"
                              autoComplete="off"
                              value={email}
                          />
                          <Form.Control.Feedback type="invalid">
                              Please enter a valid Email.
                          </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="state">
                          <Form.Control
                              value={propAddressState}
                              placeholder="Property State"
                              onChange={(e) => {
                              setPropAddressState(e.target.value);
                              }}
                              as="select"
                          >
                              <option value="">Your State</option>
                              {statesOptions}
                          </Form.Control>
                      </Form.Group>
                      <div className="consentDesc">

                      <p className="mt-2 mb-1">
                          <strong>Phone Solicitation Consent</strong>
                      </p>        

                      <p className='mb-0'>
                      By clicking I Consent below, you expressly consent to Sun West Mortgage Company, Inc. 
                      making (or allowing to be made on its behalf) a phone call, text message, or voicemail 
                      transmission to the phone number you entered above for the purpose of soliciting your purchase 
                      of consumer goods or services (including using an automated system for the selection or dialing 
                      of phone numbers, the playing of a recorded message when a connection is made, or the transmission
                      of a pre-recorded voicemail), even if that phone number is a on a national, state, or internal Do 
                      Not Call Registry. You are not required to click on I Consent below (directly or indirectly) or 
                      agree to receive such phone calls, text messages, or voicemail transmissions as a condition of 
                      obtaining any goods or services from Sun West Mortgage Company, Inc. or its affiliates.</p>             
                      
                      <Row className="inputRow">
                          <Form.Check
                          inline
                          label="I consent"                        
                          type="checkbox"
                          className="checkboxConsent"
                          value="true"
                          checked={consent}
                          onChange={() => setConsent(true)}
                          id=""
                      />

                      <Form.Check
                          inline
                          label="I do not consent"                        
                          type="checkbox"
                          value="false"
                          className="checkboxNotConsent"
                          checked={consent === false}
                          onChange={() => setConsent(false)}
                          id=""
                      />

                      </Row>

                      <p className="m-0">
                      By pressing Call Me, you agree to Sun West Mortgage
                        Company, Inc.'s NMLS 3277 ("Sun West"){" "}
                        <a
                          href="https://www.swmc.com/terms-and-conditions"
                          target="_blank"
                          className="form-link"
                          rel="noopener noreferrer"
                        >
                          Terms and Conditions
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://www.swmc.com/privacy-policy"
                          target="_blank"
                          className="form-link"
                          rel="noopener noreferrer"
                        >
                          Security and Privacy Policy
                        </a>
                        ; and you agree to Celligence International, LLC.'s
                        ("Celligence"){" "}
                        <a
                          href="https://www.celligence.com/terms"
                          target="_blank"
                          className="form-link"
                          rel="noopener noreferrer"
                        >
                          Terms and Conditions
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://www.celligence.com/privacy"
                          target="_blank"
                          className="form-link"
                          rel="noopener noreferrer"
                        >
                          Security and Privacy Policy
                        </a>
                        , all of which you may access by clicking on the "Terms
                        and Conditions" or "Security Policy" web links contained
                        herein.</p>
                      
                      </div>

                      <Col className="text-center pt-2">
                        <Button variant="primary" disabled={loading} type="submit" className="d-flex m-auto align-items-center">
                            {loading ? (
                                <>
                                    <Spinner
                                        animation="border"
                                        aria-hidden="true"
                                        as="span"
                                        role="status"
                                        size="md"
                                    />
                                    &nbsp;&nbsp;Please&nbsp;wait...
                                </>
                            ) : (
                                "Call Me"
                            )}
                        </Button>
                      </Col>
                    </Form>
                  </Col>
                </Row>   
                <Modal centered show={showSent}>
                    <Modal.Body>
                        <p>Thank you for contacting Sun West Mortgage Company, Inc. We will contact you as soon as possible.</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={refreshPage} variant="secondary">Close</Button>
                    </Modal.Footer>
                </Modal> 

                <Modal centered show={showFail}>
                    <Modal.Body>
                        <h4>Please try again.</h4>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={refreshPage} variant="secondary">Close</Button>
                    </Modal.Footer>
                </Modal> 
            </Container>            
        </StyleWrapper>
    )
}

const StyleWrapper = styled.div`

.consentDesc p{
    color: #000 !important;
max-width: 100%;
text-align: left;
font-size: 10px !important;
margin-left : 0 !important;
padding-bottom: 5px;
margin-bottom: 5px;
}

.consentDesc .form-check-label {
    color: #000;
}
.consentDesc .inputRow{
   padding-left: 10px;
}
.consentDesc .form-check-inline{
    width: auto;
    display: flex;
    font-size: 12px;
}

`
