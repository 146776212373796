import React from "react";
import { Link } from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../css/home.css";

import Guiding from "../components/Guiding"
import JoinUs from "../components/JoinsUs"
import Resources from "../components/Resources";
import RecentBlogs from "../components/RecentBlogs";
import Subscribe from "../components/Subscribe"

export default function Home({setLoanPurpose, setModalShow, setOfferLoanType}) {
  return (
    <div>
      <Container fluid id="mainBanner" className="px-0">
        <Row className="no-gutters">
          <Col>
            <div
              id="baner-text-styl"
              className="fl-col fl-node-5bcda06122592 fl-col-small float-right"
              data-node="5bcda06122592"
            >
              <div className="fl-col-content fl-node-content">
                <div
                  className="fl-module fl-module-pp-heading fl-node-5bcda08a65ef3"
                  data-node="5bcda08a65ef3"
                >
                  <div className="fl-module-content fl-node-content">
                    <div className="pp-heading-content">
                      <div className="pp-heading  pp-center">
                        <h2 className="heading-title">
                          <span className="title-text pp-primary-title pb-0">
                            FIND OUT FOR HOW MUCH YOU QUALIFY
                          </span>
                          {/* <span className="title-text pp-secondary-title">
                            SIMPLY CHOOSE THE OPTION BELOW THAT BEST DESCRIBES
                            YOUR LOAN GOAL
                          </span> */}
                        </h2>
                      </div>
                      <div className="pp-sub-heading"></div>
                    </div>
                  </div>
                </div>
                <div
                  id="double_button"
                  className="fl-module fl-module-pp-dual-button fl-node-5bcda1e3a2038"
                  data-node="5bcda1e3a2038"
                >
                  <div className="fl-module-content fl-node-content mt-0">
                    <div className="pp-dual-button-content clearfix">
                      <div className="pp-dual-button-inner">
                        <div className="pp-dual-button-1 pp-dual-button">
                          <button className="pp-button" onClick={() => {
                            setLoanPurpose('LIMITED CO');
                            setModalShow(true);
                            setOfferLoanType('');
                          }}>
                            <span className="pp-button-1-text">REFINANCE</span>
                          </button>
                        </div>
                        <div className="pp-spacer"></div>
                        <div className="pp-dual-button-2 pp-dual-button">
                          <button className="pp-button" onClick={() => {
                            setLoanPurpose('PURCHASE');
                            setModalShow(true);
                            setOfferLoanType('');
                          }}>
                            <span className="pp-button-2-text">PURCHASE</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Guiding
        setLoanPurpose={setLoanPurpose}
        setModalShow={setModalShow}
        setOfferLoanType={setOfferLoanType}
      />
      <JoinUs />
      <Resources />
      {/* <RecentBlogs /> */}
      <Subscribe />
    </div>
  );
}
