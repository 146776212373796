import React from "react";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import StyledHero from "../components/StyledHero";
import Banner from "../components/Banner";
import Subscribe from "../components/Subscribe";
import RequestCallback from "../components/RequestCallback";

import FaqImg from "../images/faq-banner.jpg";

import "../css/FaqAccordion.css";

export default function Faq({
  isSWMC,
  setLoanPurpose,
  setModalShow,
  setOfferLoanType,
}) {
  return (
    <div>
      <Helmet>
        <title>FAQ | Sun West</title>
        <meta
          name="description"
          content="FAQ to help answer numerous questions about the loan and mortgage process."
        />
      </Helmet>
      <StyledHero img={FaqImg} bgPosition="bottom center">
        <Banner title="FAQ" headerFooter={true}
          isSWMC={isSWMC}
          setLoanPurpose={setLoanPurpose}
          setModalShow={setModalShow}
          setOfferLoanType={setOfferLoanType}></Banner>
      </StyledHero>
      <Container fluid>
        <Row>
          <Col sm={12} className="text-center py-4" style={{backgroundColor: '#f2f2f2'}}>
            <div class="fusion-title title fusion-sep-none fusion-title-size-two fusion-border-below-title">
              <div class="fusion-title title fusion-sep-none fusion-title-center fusion-title-size-two fusion-border-below-title">
                <h2 class="title-heading-center">
                  <strong>
                    <span>Frequently Asked Questions</span>
                  </strong>
                </h2>
              </div>
              <div class="fusion-text">
                <p>
                  Many homebuyers interested in getting a mortgage often
                  encounter questions and concepts during the
                  <br />
                  mortgage process that they may not fully understand. This
                  Q&amp;A section is created to answer some of the
                  <br />
                  most common questions and concepts that you will likely see
                  when learning about mortgages.
                </p>
              </div>
            </div>
          </Col>
        </Row>
    </Container>
    <Container className="mt-5 pb-5">
        <Row>    
          <Col className="FaqAccordionCol">
          <Accordion className="faq-accordion">
            <Card>
              <Accordion.Collapse eventKey="0" className="faq-answer">
                <Card.Body>
                First, you will need to submit a mortgage application with us, either in person, by phone, or using our easy-to-use online form, whichever makes you feel more comfortable. One of our licensed loan officers will get in touch with you and discuss your mortgage needs and objectives with you. Next, we will check your credit scores and request you to submit the necessary supporting documents so that we can verify your identity, the source of your income, and your current debt for underwriting purposes. An appraisal will also be performed on your selected property. Once you have submitted all the documents and your loan is approved with no outstanding items, we will then prepare the closing documents so that you can get ready to sign your loan package. After your loan is funded, you are on your way to move into your new home.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="faq-question">
                What does getting a mortgage involve?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="1" className="faq-answer">
                <Card.Body>
                No, Sun West does not charge an application fee for you to apply for a mortgage.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="faq-question">
                Does it cost anything to apply for a mortgage?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="2" className="faq-answer">
                <Card.Body>
                Sun West has one of the fastest turnaround times in the industry, and our average time to close loans, even loans with FICO scores below 620, is 14 calendar days, but every borrower’s situation is different, and due to documentation requirements and varying response times from the borrower, the average time to close a loan may be higher.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="faq-question">
                How long does it take to close my loan?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="3" className="faq-answer">
                <Card.Body>
                A mortgage refinance refers to obtaining a new loan for the purpose of lowering your mortgage payments, converting your existing loan into a more affordable or manageable loan, or getting cash out on available equity on your home. There may be a minimum waiting period from the date you closed your previous mortgage, based on the type of refinance you are applying for. Sun West also recommends that you check with your existing lender regarding any prepayment penalties. There will be fees involved when refinancing your home, although you may have the option of rolling these costs into your new loan. Please call one of our licensed loan officers at-&nbsp;<a href="tel:+18004537884">(800) 453-7884</a>&nbsp;to discuss whether a refinance is right for you.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="faq-question">
                What is a mortgage refinance?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="4" className="faq-answer">
                <Card.Body>
                Most types of mortgages require a minimum amount of down payment, except for VA and USDA programs. You may also be eligible for down payment assistance programs that can help you towards minimum down payment requirement on some of the loan programs. On Conventional Mortgages, your lender will require you to pay a Private Mortgage Insurance (PMI) premium as part of your monthly payments if you put down less than 20% of the purchase price of the property. There are government loan programs available with less stringent down payment requirements as well, such as an FHA loan that will require only a 3.5% down payment, but again, these programs also require mortgage insurance premiums. Finally, keep in mind that the amount of down payment you put down for a house will also affect your Loan-to-Value (LTV) ratio, which could then affect the amount of loan you are able to qualify for, or whether or not you will qualify at all.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="4"
                className="faq-question faq-last">
                What amount of down payment is required in order to obtain a mortgage?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="5" className="faq-answer">
                <Card.Body>
                Mortgage insurance has been established by lenders and government agencies as a means to protect the investor before the mortgage is fully paid off. When a borrower defaults on his or her loan and the property is forced to be sold by foreclosure, the property may be sold for less than the original appraised value. Mortgage insurance, in such cases, allows the investor to file a claim with the insurance issuer to claim a partial or full portion of the amount of loss.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="5"
                className="faq-question faq-last">
                Why is mortgage insurance required?	
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="6" className="faq-answer">
                <Card.Body>
                The Loan-to-Value (LTV) is a percentage of your loan in relation to your property’s appraised value or purchase price, whichever is lower. The LTV is one of the key risk measures used by mortgage banks to determine the size of the loan for which the borrower can qualify. The higher the LTV, the more risk for the lender and the less likely that a borrower will qualify for a loan.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="6"
                className="faq-question faq-last">
                What is LTV?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="7" className="faq-answer">
                <Card.Body>
                The Debt-to-Income or DTI ratio is a percentage of your monthly debt in relation to your monthly income. Simply take your monthly debt obligations, also called reoccurring debt (mortgage payment, credit card payments, loan payments, and any child support or alimony), and divide that number by your gross monthly income. Lenders prefer as low a ratio as possible (43% or less) because it increases your ability to pay for your mortgage. The lower the DTI, the more likely you will be approved for a loan.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="7"
                className="faq-question faq-last">
                What is DTI?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="8" className="faq-answer">
                <Card.Body>
                PITI stands for Principal, Interest, Taxes, and Insurance and is a part of your monthly mortgage payments. In the financial industry, the PITI is also referred by some as the &quot;Front-End&quot; DTI, and may be analyzed separately or as a whole within your total DTI.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="8"
                className="faq-question faq-last">
                What does PITI stand for?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="9" className="faq-answer">
                <Card.Body>
                Your mortgage interest rate is dependent upon a number of factors, including, but not limited to your credit score, size of down payment, loan purpose, occupancy, LTV ratio, and DTI ratio. Different mortgage products have different guidelines that may also indirectly affect what rates you can get. The base interest rate in the market is determined by the secondary market through competing U.S. Treasury bonds, the price of which is determined by the market demand. All these factors combined will determine what interest rate you will get for your mortgage at a particular time. When you are ready to secure your interest rate during your mortgage application, you should contact your licensed loan officer to go over the lock process in more detail.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="9"
                className="faq-question faq-last">
                What determines my mortgage interest rate?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="10" className="faq-answer">
                <Card.Body>
                Fixed-Rate mortgages allow you to lock in your mortgage payments (principal and interest) for the life of your mortgage and your monthly mortgage amounts will never change; whereas an Adjustable Rate Mortgage (ARM) will have lower fixed, initial interest rates, as compared with other fixed rate products, during the initial introductory period and the rate will reset to the prevailing market rate once the initial period is over. There are many factors to consider when deciding whether a Fixed Rate or an ARM is better for you. Please consult one of our licensed mortgage experts to determine which option is best for you.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="10"
                className="faq-question faq-last">
                What is the difference between Fixed-Rate and Adjustable-Rate Mortgage?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="11" className="faq-answer">
                <Card.Body>
                Closing costs are the fees associated with acquiring a mortgage loan. Such closing costs include but are not limited to:a. Lender fees
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="11"
                className="faq-question faq-last">
                What are closing costs?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="12" className="faq-answer">
                <Card.Body>
                The origination charge includes fees for loan application, document preparation, underwriting, and processing. The origination fee is the fee(s) earned by the lender and/or broker for providing you the service when making the loan. The origination fee does not include any discount points and is a part of the total closing costs.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="12"
                className="faq-question faq-last">
                What are origination charges?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="13" className="faq-answer">
                <Card.Body>
                A discount point is a point that a borrower may choose to pay to lower the interest rate on a loan. A charge of one point is equal to 1% of the loan amount. Most lenders will typically allow the purchase of a maximum amount of 3-4 points to help lower the interest rate. You should consult your loan officer on how much interest rate you are able to buy down with each point. Depending on how much it will help lower your monthly mortgage payments and how long you plan to stay in your home, this option may or may not make sense for you in terms of overall savings.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="13"
                className="faq-question faq-last">
                What is a &quot;discount point&quot;?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="14" className="faq-answer">
                <Card.Body>
                This process is formally known as a rate lock. After you submit your application, you are eligible to lock your loan. If you do not wish to lock at the time of application, you may choose to check with your loan officer regularly to check the current interest rate and then lock at a later time. It is important to note that until you lock your loan, your interest is subject to market fluctuations and may go up until it is locked. The rate lock must be good (i.e. should not expire) until the date of funding of your mortgage loan.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="14"
                className="faq-question faq-last">
                What is &quot;locking&quot; your loan?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="15" className="faq-answer">
                <Card.Body>
                An appraisal is required by your lender to professionally determine the value of the property you wish to buy. A licensed appraiser will be chosen to inspect the features of the house, the quality of the build, age, etc. The appraiser will then compare the asking price of the property to recent home sale data in the region to see if it is of fair market value. A lender will not approve a loan where the purchase price is greater than the appraised value unless you agree to bring in the difference of the two as part of your funds at closing. An inspection, on the other hand, should be done by the buyer to make sure that the home is in good condition and does not require any repairs before the actual purchase. An inspection is performed to make the buyer aware of any defects so that he or she can resolve the problem before the purchase or walk away from the deal. An appraisal protects the lender while an inspection protects the borrower.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="15"
                className="faq-question faq-last">
                What is the difference between an appraisal and a home inspection?	
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="16" className="faq-answer">
                <Card.Body>
                Though the appraisal is primarily used for the lender to know that they are not approving a loan in excess of the property value, lenders will still give you a loan for the lower of appraised value and sales price.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="16"
                className="faq-question faq-last">
                What if the appraised value of my selected property is higher than the sales price?	
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="17" className="faq-answer">
                <Card.Body>
                There are a few insurance types that are required when you get your mortgage, and each has a unique purpose.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="17"
                className="faq-question faq-last">
                What are the different types of insurance required when getting a mortgage?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="18" className="faq-answer">
                <Card.Body>
                With respect to mortgages, equity refers to the difference between the market value of the property and the amount currently owed on the mortgage. It is basically the amount of money the owner will be able to keep for himself or herself after selling the house and after paying off the remaining mortgage amount.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="18"
                className="faq-question faq-last">
                What is the meaning of equity?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="19" className="faq-answer">
                <Card.Body>
                The term escrow can refer to two things: the escrow process or an escrow account. The escrow process is an important part of the mortgage origination where a third party company is assigned to keep control of all funds and documents for all parties (seller, buyer, lender) in the transaction. Funds are sent to the escrow company to be processed and verified, and the escrow company, upon disbursement, prepares a settlement statement summarizing the distribution of funds and the terms of your mortgage. An escrow account usually refers to an account for a mortgage by a lender to hold funds that will be used to pay property taxes and insurance, including mortgage insurance. The payments made from an escrow account will be shown in your monthly mortgage statements, so you don’t have to worry about managing these payments separately.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="19"
                className="faq-question faq-last">
                What is Escrow?
              </Accordion.Toggle>
            </Card>
          </Accordion>
          </Col>
        </Row>
      </Container>

      <Col sm={12} className="mt-5 pt-5 px-0">
        <RequestCallback className="mt-5" />
      </Col>
      <Col sm={12}>
        <Subscribe />
      </Col>
    </div>
  );
}
