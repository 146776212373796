import React, { Suspense, useEffect, useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import axios from 'axios';
import parser from 'html-react-parser';
import './SubFooter.css';


function SubFooter({setLoanPurpose, setModalShow, setOfferLoanType}) {

    const ref = useRef();
  const [isIntersecting, setIntersecting] = useState(false);
  const [footerInfoData, setFooterInfoData] = useState();


  useEffect(() => {
    /**
     * @author Pratik Kajrolkar
     * @date 07/17/2023
     * The below function is used to fetch the swmc footer data which
     * is stored in master JSON in S3 bucket.
     * @returns JSON Object of the requested file
     */
    const fetchSWMCFooterInfo = async () => {
      try {
        await axios
          .get(process.env.REACT_APP_SWMC_FOOTET_INFO)
          .then(response => {
            //console.log(response.data);
            setFooterInfoData(response.data);
            const observer = new IntersectionObserver(([entry]) => {
              if (entry.isIntersecting) {
                setIntersecting(entry.isIntersecting);
                observer.disconnect();
              }
            });
            observer.observe(ref.current);
            // Remove the observer as soon as the component is unmounted
            return () => {
              observer.disconnect();
            };
          })
          .catch(error => {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    };

    fetchSWMCFooterInfo();
  }, []);

    return (
       
        <div>
            
             <section className="sub-footer text-center box-shadow">
             {footerInfoData ? (
            <>
                <Container className="p-4">
                    <Row>
                        <Col  ms={12} md={4}>
                            <h3>About Us</h3>
                            <div className="m-3">
                                <p>At Sun West Mortgage Company, Inc (NMLS ID 3277), we dedicate ourselves to offering the best service to our customers.</p>
                            </div>
                        </Col>
                        <Col  ms={12} md={4}>
                            <h3>CONTACT US</h3>
                            <div className="m-3">

                                <p> <a  href={
                                footerInfoData.contactUs.address.googleMapsUrl
                              } target="_blank">{footerInfoData.contactUs.address.fullAddress}<br></br></a>
                                Phone: <a href="tel:+1(844)765-6844" target="_blank" rel="noopener noreferrer">(844) 765-6844</a></p>
                            </div>
                        </Col>
                        <Col  ms={12} md={4}>
                            <h3>RESOURCES</h3>
                            <div className="m-3">
                                <p>
                                    <Link to="../home-buyers-guide">Home Buyer&#39;s Guide</Link><br />
                                    <Link to="../refinancing-guide">Refinancing Guide</Link><br />
                                    <button className="btn-link" onClick={()=>{
                                        setLoanPurpose('');
                                        setModalShow(true);
                                        setOfferLoanType('');
                                    }}>Apply Now</button>
                                </p>
                            </div>
                        </Col>                        
                    </Row>
                    <Row className=" pt-4">
                        <Col className="text-sm-center text-md-end pl-3" sm={12} md={{ span: 3, offset: 2 }}>
                            <h3>
                                <span className="fl-heading-text">GET SOCIAL</span>
                            </h3>
                        </Col>
                        <Col className="text-sm-center text-md-start p-3 p-sm-3 p-md-0" sm={12} md={3}>
                        <span>
                            <span className="p-2">
                                <link href="https://swmcretail.com/" />
                                <a href="https://www.facebook.com/SunWestMortgage" target="_blank" rel="noopener noreferrer" title="Facebook" aria-label="Facebook" role="button">
                                    <FontAwesomeIcon icon={faFacebookSquare} className='react-icons' />
                                </a>
                            </span>
                            <span className="p-2">
                                <link href="https://swmcretail.com/" />
                                <a href="https://twitter.com/SunWestMortgage" target="_blank" rel="noopener noreferrer" title="Twitter" aria-label="Twitter" role="button">
                                  <FontAwesomeIcon icon={faTwitter} className='react-icons' />
                                </a>
                            </span>
                            <span className="p-2">
                                <link href="https://swmcretail.com/" />
                                <a href="https://www.linkedin.com/company/sun-west-mortgage-company/about/" target="_blank" rel="noopener noreferrer" title="LinkedIn" aria-label="LinkedIn" role="button">
                                <FontAwesomeIcon icon={faLinkedin} className='react-icons' />
                                </a>
                            </span>
                        </span>
                        </Col>
                        
                        
                        <Col sm={12} md={4}>
                            <h3>
                                NMLS ID 3277
                            </h3>
                        </Col>
                    </Row>
                </Container>
                </>
          ) : (
            ''
          )}
            </section>
        </div>
       
       
    )
}

export default SubFooter;