import React , { useState, useEffect, lazy, Suspense } from 'react';
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import {  imagePath, yTVideoArray } from '../../utils/helpers';
import { Image } from "react-bootstrap";
import { Loader } from './Loader';
import styled from 'styled-components'
  
const ReactPlayerWrapper = lazy(() => import('./ReactPlayerWrapper'));


const VideoCarousel = (props) =>{

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};




const [isOpenSingleVideo, setIsOpenSingleVideo] = useState(false)
    const [currentVideoId, setCurrentVideoId] = useState('')
    const [isLoading, setisLoading] = useState(['', '', ''])

    const handleThumbnailClick = (e, id) => {
        e.preventDefault()
        setIsOpenSingleVideo(true)
        setCurrentVideoId(id)
    }

    const latestLoadingUpdate = (currentState) => {
        setisLoading(currentState)
    }

    const handleCustomArrowClick = (e, arrowType) => {
        e.preventDefault()
        const domInbuiltArrowName = arrowType === 'Next' ? 'right' : 'left'
        const inbuiltArrow = document.querySelectorAll('.react-multiple-carousel__arrow.react-multiple-carousel__arrow--' + domInbuiltArrowName)

        if (inbuiltArrow && inbuiltArrow.length === 1) {
            inbuiltArrow[0].click()
        }
    }
    const handleOverlayCloseClick = (e) => {
        setIsOpenSingleVideo(false)
        setCurrentVideoId('')
    }

    
    return (
      <StyleWrapper>
        <div className="gallery-section-container pt-4">

          <div className="top-section">
            <div className="table-title broker-videos-title">
              {props.subheading && 
              <h4>{props.subheading}</h4> }

              {props.title && <h2>{props.title}</h2>}
              
            </div>

            <div className="custom-arrows-div">

            <div className="slider-custom-arrows arrow-left"
                    style={{
                        backgroundImage: `url(${imagePath}/video/arrow_left.svg)`
                    }}
                    onClick={(e) => handleCustomArrowClick(e, 'Previous')}
                >
                </div>
                <div className="slider-custom-arrows arrow-right"
                    style={{
                        backgroundImage: `url(${imagePath}/video/arrow_right.svg)`
                    }}
                    onClick={(e) => handleCustomArrowClick(e, 'Next')}
                >
                </div>

                </div>

                

            </div>        


            
          
                
                <div className='video-grid'>

                    <Carousel
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        responsive={responsive}
                        infinite={false}
                        centerMode={true}
                        autoPlaySpeed={10000}
                        keyBoardControl={true}
                        autoPlay={false}
                        customTransition="all .5"
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                        {
                            yTVideoArray.slice(0, yTVideoArray.length).map((item, index) => (
                                    <div
                                        className="thumbnail-container" key={index}
                                    >
                                            <div className={
                                                `image-wrapper ${item.id === currentVideoId ? 'now-playing' : ''
                                                }`
                                            }>
                                                <Image
                                                    className="thumbnail-image"
                                                    src={(item.thumbnail)}
                                                    onClick={(e) => {
                                                        handleThumbnailClick(e, item.link )
                                                    }}
                                                    draggable="false"
                                                    width="320" 
                                                    height="180"
                                                    alt=""
                                                    srcSet={`${item.thumbnail} 1650w, ${item.thumbnail} 300w, ${item.thumbnail} 768w`}
                                                    sizes="(max-width:1650px) 100vw, 1650px"
                                                    // style={{height:"auto"}}
                                                ></Image>
                                            </div>

                                        {/* } */}
                                    </div>
                            ))
                        }
                    </Carousel>
            </div>
          
            {isOpenSingleVideo &&
                <div
                    className={`react-video-overlay ${isLoading ? 'loader-present' : ''}`}
                    id="draggable" >
                    <div id="draggableHeader" title="Hold and drag to move this window">
                        <button title="Close" className="react-video-overlay--close-btn"
                            onClick={(e) => handleOverlayCloseClick(e)}
                        ><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABmJLR0QA/wD/AP+gvaeTAAAA3UlEQVRIie2VSw6CQBBEwRMorv3g/c8hxI1bNArhJM+Fk0BkpqeAmGhCrat5VM9Md5Is+ksBG6AE7sBB8B+ctwQyFbIGLnRqgZPh3wPPnv8KbBVQwVC1L5lLUnv8RQySAjdP4QBmQHDfSGOwz1b01QK54Am22teSxkgWStIARwkiJpuXZAasBfJJkBEwCbKa9Red7BsW08jWff18pp8T9mNssK9+dDYqSdQHayfDHkEN+giqEEZQGYMIMHuoumLfmgi2AtgBj55fWxOuOOO9LipfkkCyCjijLr5FP6cXh7JvcWVu5G0AAAAASUVORK5CYII="/></button>
                    </div>
                    <Suspense fallback={<Loader />}>
                        <ReactPlayerWrapper
                            width="auto"
                            height="200"
                            url={currentVideoId}
                            latestLoadingUpdate={latestLoadingUpdate}
                            // deviceType={deviceType}
                        />
                    </Suspense>
                </div>
            }
        </div>
        </StyleWrapper>
    );
}


const StyleWrapper = styled.div`
.gallery-section-container .thumbnail-container {
  padding: 10px;
  padding-left: 0;
  padding-right: 0;
}

.react-multi-carousel-list {
 
  // justify-content: center;
}

@media (max-width: 450px){
  .react-multi-carousel-list {
 
    justify-content: inherit;
  }
}

.gallery-section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  gap: 1rem;
  padding: 30px;
}

.gallery-section-container .react-multi-carousel-track {
  gap: 15px;
}

.table-title h4, .table-title h2{
  color: #0A6EB5
}

.top-section {
  display: flex;
  position: relative;
}

.top-section::before {
  content: "";
position: absolute;
left: 0;
bottom: 0;
height: 1px;
width: 50%;
border-top: 1px solid #083F88;
opacity: 0.5;
}

.custom-arrows-div{
  display: flex;
gap: 5px;
position: absolute;
right: 0;
top: 50%;
}

.react-player-container {
  width: 100%;
  position: relative;
}

.react-player-class-container {
  aspect-ratio: 16/9;
}
@supports not (aspect-ratio: 16/9) {
  .react-player-class-container {
    width: 100% !important;
    height: 50vh !important;
  }
}

.react-player-container > * {
  max-width: 90%;
  margin: 0 auto;
}

.video-grid {
  // display: grid;
  // justify-content: center;
  // grid-auto-columns: 50px minmax(200px, 100vw) 50px ;
  align-content: center;
  align-items: center;
}

.middle-section {
  display: flex;
  flex-direction: column;
}


.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right,
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  display: none;
}
.slider-custom-arrows {
  align-self: center;
  justify-self: center;
  border-radius: 3px;
  font-family: 'Roboto-Bold';
  background: #ebebeb;
  color: #808080;
  width: fit-content;
  padding: 1rem;
  line-height: 1;
  cursor: pointer;
  border: 1px solid transparent;
}
.slider-custom-arrows:hover {
  border: 1px solid #00AAE4;
  border-radius: 3px;
  // opacity: 0.5;
}
.next-btn {
  position: absolute;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: yellow;
  min-width: 43px;
  min-height: 43px;
  right: 0;
  cursor: pointer;
}

.thumbnail-image {
  width: 100%;
  cursor: pointer;
  border-radius: 20px;
  // max-height: 180px;

  // @media (min-width: 960px) and (max-width: 1100px){

  //   max-height: 130px;

  // }

  // @media (min-width: 1200px) and (max-width: 1320px){

  //   max-height: 160px;

  // }
}

.thumbnail-image:hover {
  box-shadow: 0px 0px 8px #1e4383;
}

button.gallery-view-more-btn.btn-primary{
  font-size: 18px;
  font-family: 'Roboto-Medium';
  border-top-left-radius: 15px ;
  border-top-right-radius: 0 ;
  border-bottom-left-radius: 0 ;
  border-top: 1px solid #083f88 !important;
  border-left: 1px solid #083f88 !important;
  width: fit-content;
  align-self: flex-end;
  padding: 2px 10px;
  background-color: #083f88;
  color: #fff;
  text-shadow: 0 0 3px #fff;
  /* text-shadow: -1px 0px 2px #083f88; */
  /* -webkit-box-shadow: inset 0px 0px 11px 2px #083f88; 
  box-shadow: inset 0px 0px 11px 2px #083f88; */
  position: absolute;
  bottom: 4%;
}
button.gallery-view-more-btn.btn-primary:hover{
  /* -webkit-box-shadow: inset 0px 0px 11px 6px #083f88; 
  box-shadow: inset 0px 0px 11px 6px #083f88; */
  box-shadow: 0 0 4px 2px #2ba8e0;
  transition: all 0.2s linear;
}
button.gallery-view-more-btn.btn-primary:active,
button.gallery-view-more-btn.btn-primary:focus {
  outline: none;
  box-shadow: 0 0 0 1px #083f88;
}

.view-more-card {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #808081;
  position: absolute;
  width: 100%;
  height: 85%;
  font-family: 'Roboto-Bold';
  background-color: #eaebec;
  line-height: 1;
}

.custom-right-arrow {
  position: absolute;
  right: 0;
  bottom: -5%;
  z-index: 3;
}

.react-video-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  width: 45vw;
  z-index: 10;
  cursor: move;
}

.react-video-overlay .react-player-class-container {
  border: 2px solid #2ba8e0;
  border-top: 0
}

.react-video-overlay--close-btn {
  content: '';
  color: #fff;
  font-family: 'Roboto-Bold';
  background: transparent;
  border-color: transparent;
  box-sizing: border-box;
  border-radius: 0;
  font-size: 1.5rem;
  margin-left: auto;
  line-height: 1;
  inset: -10px -10px auto auto;
  z-index: 10;

}

.react-video-overlay--close-btn:hover{
  text-shadow: 0 0 3px #0277ad;
}

#draggableHeader {
  display: flex;
  width: 100%;
  background: -moz-linear-gradient(left, #034390 0%, #fff 50%, #034390 100%);
  background: -webkit-linear-gradient(left, #034390 0%, #fff 50%, #034390 100%);
  background: linear-gradient(to right, #034390 0%, #fff 50%, #034390 100%);
  border: 2px solid #2ba8e0;
  border-bottom: 0;
  cursor: move;
}

.react-video-overlay.loader-present{
  background-color: #fff;
  opacity: 0.8;
  transition: all 0.2s linear;
}

@media (min-width: 768px) and (max-width: 1025px) {
  .react-video-overlay {
      width: 65vw;
  }
  @supports not (aspect-ratio: 16/9) {
      .react-player-class-container {
          width: 100% !important;
          height: 42vh !important;
      }
  }
}
@media (max-width: 768px) {
  .react-video-overlay {
      width: 90vw;
  }
  @supports not (aspect-ratio: 16/9) {
      .react-player-class-container {
          width: 100% !important;
          height: 42vh !important;
      }
  }
  @supports not (aspect-ratio: 2/2) {
      .react-player-class-container {
          width: 100% !important;
          height: 42vh !important;
      }
  }
}
@media (min-width: 1000px) {

  .table-title.broker-videos-title {
      font-size: clamp(30px, calc(30px + 2vw) , 45px);
      line-height: 1.5;
  }
}
@media (max-width: 575px) {

  .thumbnail-image{
    height: 100px !important;
  }

  .broker-videos-title h2{
      font-size: 24px;
  }

  .broker-videos-title h4{
    font-size: 18px;
}

  .gallery-section-container .react-multiple-carousel__arrow{
      opacity: 0.8;
  }

}

@media only screen and (max-width: 399px) {
  button.gallery-view-more-btn.btn-primary {
      font-size: 12px;
      padding: 2px 6px;
  }

  .gallery-section-container .react-multi-carousel-track {
      gap: 7px;
  }

  // .video-grid {
  //     grid-auto-columns: 36px minmax(200px, 80vw) 36px;
  // }

  .slider-custom-arrows {
      padding: 0.8rem;
  }

  .react-player-class-container {
      aspect-ratio: 2/2;
  }

  @supports not (aspect-ratio: 2/2) {
      .react-player-class-container {
          width: 100% !important;
          height: 42vh !important;
      }
  }
}

.react-video-modal .modal-header {
  background-color: transparent;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: none;
  background: linear-gradient(90deg, rgba(43,57,140,1) 0%, rgba(11,79,155,1) 35%, rgba(7,114,183,1) 100%);
  border-color: transparent;
}
.react-video-modal .modal-title {
  color: #fff;
}
.react-video-modal .modal-header .close{
  color: white;
  opacity: 1;
}
.react-video-modal .modal-content {
  box-shadow: inset 0em 0em 7px #1e4383;
  border: 0;
  gap: 1rem;
}

.react-multiple-carousel__arrow--left {
  left: calc(2% + 1px);
}
.react-multiple-carousel__arrow--right {
  right: calc(2% + 1px);
}

.video-player-body > * {
  width: fit-content;
  margin: 0 auto;
}

.fallback-react-player {
  width: 2rem;
  height: 2rem;
  position: absolute;
  align-items: center;
  left: 50%;
  top: 50%;
  background: black;
  opacity: 0.5;
}

.error-div {
  font-family: "Roboto-Medium";
  color: #083f88 !important;
  position: absolute;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media(min-width: 575px) {

  .video-player-body.modal-body {
      padding-top: 0;
  }
}
@media(max-width: 575px) {
  .react-video-modal .modal-title {
      font-size: 18px;
  }
}

`

export default VideoCarousel