import React from "react";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StyledHero from '../components/StyledHero';
import Banner from '../components/Banner';
import HowItWorks from "../components/HowItWorks";
import FaqAccordion from "../components/FaqAccordion";
import Subscribe from "../components/Subscribe";
import RequestCallback from "../components/RequestCallback";

import OurStoryImg from "../images/Our-story-banner.jpg"

export default function OurStory({
  isSWMC,
  setLoanPurpose,
  setModalShow,
  setOfferLoanType,
}) {

  return (
  <div>
       <Helmet>
        <title>Our Story | Sun West</title>
        <meta
          name="description"
          content="Sun West is dedicated to offering the best service to their customers. Their loan experts are here to help you find the perfect loan for you."
        />
      </Helmet>
      <StyledHero img={OurStoryImg} bgPosition="center center">
          <Banner title="OUR STORY" headerFooter={true}
          isSWMC={isSWMC}
          setLoanPurpose={setLoanPurpose}
          setModalShow={setModalShow}
          setOfferLoanType={setOfferLoanType}>
          </Banner>
      </StyledHero> 

      <Container>
        <Row>
            <Col id="OurStory-wrapper" className="pt-5">
              <h2>Why Choose Sun West?</h2>
              <p>At Sun West, we dedicate ourselves to offering the best service to our customers. To accomplish this, we empower our loan officers so that they can find great rates and provide the best fitting loan options to each customer – at amazing speed. Our focus on technology has given us an edge in the mortgage industry to offer some of the fastest turn times available –<strong>&nbsp;so you can get into the home of your dreams sooner!</strong></p>
              <p>We are committed to our core values of people, experience, technology, and product. Sun West was founded in 1980 with the perspective of “clients first” and the desire to make the mortgage process easy and stress free for prospective homeowners. Since then, Sun West&nbsp;services several multi-billion dollar loan portfolio and is licensed to lend in 50 states,&nbsp;District of Columbia, Puerto Rico, and the US Virgin Islands. Our nearly 40 years of experience has been passed down to everyone here at Sun West through excellent leadership and loan capabilities.</p>
              <h2>Mortgages Don&#39;t Have to Be a Headache</h2>
              <p>All of our loan experts are dedicated to helping you find the perfect loan for your needs and goals. Our website is also filled with information meant to help you choose the right loan for your personal needs and preferred monthly payment obligations. We even offer straightforward guides to help you decide what route to take based on your mortgage goals: check out our&nbsp;<Link to="/home-buyers-guide">home buyer&#39;s guide</Link>&nbsp;and&nbsp;<Link to="/refinancing-guide">refinancing guide</Link>. We want you to feel confident as you make this decision so we strive to provide you with all the resources necessary.</p>
              <p>Read about&nbsp;<Link to="/our-mortgage-process">our mortgage process</Link>&nbsp;or&nbsp;<Link to="/contact-us">contact us today</Link>&nbsp;to learn more about how we can assist you with your home financing!</p>
              <h2>Our Mission</h2>
              <p>Our Mission at Sun West is to foster an environment of unparalleled support and technology advancement for our loan officers so that they can be equipped to provide their clients with the best customer service and most competitive financing options available.</p>
              <h2>Our Values</h2>
              <p><strong>People:</strong>&nbsp;We value people at every stage of the loan process. Our goal is to support and guide you every step of the way – from the moment you land on our website to the day you close on your first home to the day of your last mortgage payment.</p>
              <p><strong>Experience:</strong>&nbsp;We want to change the way you see a mortgage. More guidance, less stress – more transparency, less confusion – whatever is holding you back, Sun West is creating a better mortgage experience.</p>
              <p><strong>Technology:</strong>&nbsp;Many companies in the mortgage industry operate on outdated processes and are slow to review your paperwork which makes your offer less competitive and takes you longer to close on the home of your dreams. We know the importance of expediting the loan process which is why we invest in<br/>
              our technology so that you can go through our loan process faster, make a better offer, and move into your home sooner.</p>
              <p><strong>Product:</strong>&nbsp;Mortgages aren&#39;t a “one size fits all” type of product, which is why our loan officers will work with you to customize your mortgage plan according to your home goals, financial needs, and plans for the future. Your Mortgage – Your Needs – It&#39;s Possible.</p>
              <h2>Leadership</h2>
              <p>Our leadership truly sets up apart. Our combined experience is at work every day strengthening our loan officers and enabling our clients to reach their goals.</p>
            </Col>
        </Row>
      </Container>
      <div className="my-5 pb-2 px-0">
        <HowItWorks />
      </div>
      <FaqAccordion />
      <Col sm={12} className="mt-5 pt-5 px-0">
        <RequestCallback className="mt-5" />
      </Col>
      <Col sm={12}>
        <Subscribe />
      </Col>
  </div>
  );
}
