import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StyledHero from "../components/StyledHero";
import Banner from "../components/Banner";
import HowItWorks from "../components/HowItWorks";
import FaqAccordion from "../components/FaqAccordion";
import Subscribe from "../components/Subscribe";
import RequestCallback from "../components/RequestCallback";

import HomeBuyerImg from "../images/home-buyers-guide-banner.jpg";

export default function HomeBuyers({
  isSWMC,
  setLoanPurpose,
  setModalShow,
  setOfferLoanType,
}) {

  return (
    <div id="resources">
      <Helmet>
        <title>Home Buyer&#39;s Guide | Sun West</title>
        <meta
          name="description"
          content="How do you know if you are ready to commit to a home? Read Sun West's home buying guide to find out!"
        />
      </Helmet>
      <StyledHero img={HomeBuyerImg} bgPosition="bottom center">
        <Banner
          title="Home Buyer&#39;s Guide"
          headerFooter={true}
          isSWMC={isSWMC}
          setLoanPurpose={setLoanPurpose}
          setModalShow={setModalShow}
          setOfferLoanType={setOfferLoanType}
        ></Banner>
      </StyledHero>
      <Container>
        <Row>
          <Col id="page-wrapper" className="pt-5">
            <h2>Get Ready to Buy a Home</h2>
            <p>
              How do you know if you and your family are ready to commit to a
              new home? If the thought of homeownership has been on your mind
              for a while, it might be a sign it&#39;s time to make the leap
              into purchasing a new property.
            </p>
            <p>
              While you may be emotionally ready, how can you be sure you&#39;re
              financially ready? Ask yourself these financial questions before
              heading into the home buying process to make sure you and your
              bank account are both ready:
            </p>
            <ul>
              <li>Is my income stable?</li>
              <li>
                Am I able to put a small percentage of my income away each month
                into savings?
              </li>
              <li>Is my monthly college and/or car debt manageable?</li>
              <li>Do I have a good credit score?</li>
              <li>
                Is my savings account already in place, and do I have enough set
                aside to pay a small down payment?
              </li>
            </ul>
            <p>
              If you answered &lsquo;yes&rsquo; to most or all of these questions, your
              financial situation is likely acceptable for home buying
              requirements.
            </p>
            <h2>View Your Home Loan Options</h2>
            <p>At Sun West, we offer the following home loans:</p>
            <ul>
              <li>
                <Link to="../fha-loans">FHA</Link>
              </li>
              <li>
                <Link to="../conventional-loans">Conventional</Link>
              </li>
              <li>
                <Link to="../jumbo-loans">Jumbo</Link>
              </li>
              <li>
                <a href="https://www.swmc.com/va-loans" target="_blank">VA</a>
              </li>
              <li>
                <Link to="../refinancing">Refinancing</Link>
              </li>
              <li>
                <Link to="../investment-property-loans">
                  Investment Properties
                </Link>
              </li>
              <li>
                <Link to="../reverse-mortgage-hecm">Reverse Mortgage</Link>
              </li>
              <li>
                <Link to="../renovation-construction-loans">
                  Renovation And Construction Loans
                </Link>
              </li>
            </ul>
            <p>
              If you&#39;re interested in learning more about the home loans
              listed above, contact a member of our team via phone or email.
              They will help explain more about each type of loan and which
              might be best for you, considering your financial situation and
              home buying goals.
            </p>
            <h2>Get Approved for a Mortgage</h2>
            <p>
              After our team explains more about the mortgage process to you,
              you will be able to make an appointment with our team if
              you&#39;re interested in moving forward with an application.
            </p>
            <p>
              After filling out and sending in your application, our team will
              review your documents to ensure that the chosen home loan is a
              good fit for your family. We will look into your household income,
              current assets, outstanding debt, and overall credit score.
            </p>
            <h2>Work With a Real Estate Agent</h2>
            <p>
              Once you&#39;re approved, congratulations! We recommend you then
              work with a real estate agent who can help you find the perfect
              house in a good neighborhood and solidify a good price on the
              home.
            </p>
            <p>
              We have a list of preferred and recommended agents. We will be
              happy to pass along their information after your mortgage
              application is approved.
            </p>
            <h2>Find Your Perfect Home</h2>
            <p>
              With your real estate agent&#39;s tips and online search tools
              from Zillow and Trulia, you can search for the best options in
              your area. Some things to consider when starting your home search:
            </p>
            <ul>
              <li>The neighborhood</li>
              <li>Local schools</li>
              <li>The commute time from your work to the home</li>
              <li>
                The nearby amenities, such as grocery stores and gas stations
              </li>
              <li>The price</li>
              <li>The number of rooms/bathrooms needed for your family</li>
            </ul>
            <p>
              Many search tools will allow you to look for homes based on some
              of the criteria above, like schools, price, and home size. If
              you&#39;re still struggling to find the right home for you, talk
              to your agent or your lender for help.
            </p>
            <h2>Make an Offer on the House</h2>
            <p>
              Once you&#39;ve found your dream home, what&#39;s next? More often
              than not, the next step is to make an offer on the home.
              Negotiating prices comes easier to some than others, so your real
              estate agent will guide you step-by-step through the process of
              making an offer.
            </p>
            <p>
              A good way to get an idea of how much to offer is to look at the
              nearby homes and what they were sold for. By finding the price of
              homes that are a comparable size and quality, you can make an
              educated assumption on a good price to offer for the home.
            </p>
            <p>
              Before making the offer, however, make sure there are no or are
              little repairs and renovations you will want to make to the house.
              If so, you will need to account for these adjustments in your
              budget and the price you can offer may differ with this in mind.
            </p>
            <h2>Get Ready to Close</h2>
            <p>
              Once your offer has been accepted, there are 3 things that will
              need to happen before you can close on the home. They are:
            </p>
            <p>
              <strong>1. A home inspection</strong>
            </p>
            <p>
              The first step requires you to find and hire an inspector to
              double check that the home is in good condition. This may seem
              like a tedious step, but it is essential&nbsp; to make sure you
              are not being ripped off by the current home owners, or will be
              faced with an unexpected issue soon after purchasing.
            </p>
            <p>
              <strong>2. An appraisal</strong>
            </p>
            <p>
              Once you&#39;ve received the home inspection, our team will order
              an appraisal for you. This step is important to make sure you
              don&#39;t pay more for the home than it&#39;s worth. A typical
              appraisal costs a few hundred dollars, so keep this in mind when
              budgeting for closing costs.
            </p>
            <p>
              <strong>3. The underwriting</strong>
            </p>
            <p>
              While the previous two steps are happening, our team will work on
              underwriting your home loan, which means we will verify your
              financial status in order to give the loan a final approval.
            </p>
            <h2>Close on Your New Home</h2>
            <p>
              For most, this is the best step of the process. After going
              through the lengthy process of finding a home and applying for a
              loan, you will finally get to sign and officiate your ownership.
              Before making it official, however, your lender will walk you
              through the Closing Disclosure document and double check that all
              details of your loan look correct. You will also need to do a
              final walk-through of the house to ensure that everything looks
              good before closing.
            </p>
            <p>
              In the instance that you miss a mortgage payment, you can always
              contact our team and we will work with you to create a new payment
              plan, if necessary. If this happens, contact your lender right
              away to discuss options.
            </p>
          </Col>
        </Row>
      </Container>
      <div className="my-5 pb-2 px-0">
        <HowItWorks />
      </div>
      <FaqAccordion />
      <Col sm={12} className="mt-5 pt-5 px-0">
        <RequestCallback className="mt-5" />
      </Col>
      <Col sm={12}>
        <Subscribe />
      </Col>
    </div>
  );
}
