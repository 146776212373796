import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StyledHero from "../components/StyledHero";
import Banner from "../components/Banner";
import HowItWorks from "../components/HowItWorks";
import FaqAccordion from "../components/FaqAccordion";
import Subscribe from "../components/Subscribe";
import RequestCallback from "../components/RequestCallback";

import FhaImg from "../images/FHA-loans-banner.jpg";

export default function FHA({
  isSWMC,
  setLoanPurpose,
  setModalShow,
  setOfferLoanType,
}) {
  return (
    <div id="productLoans">
      <Helmet>
        <title>FHA Loans | Sun West</title>
        <meta
          name="description"
          content="A FHA loan allows borrowers to receive affordable and accessible financial assistance when purchasing a home. Find out if a FHA loan is for you!"
        />
      </Helmet>
      <StyledHero img={FhaImg} bgPosition="center center">
        <Banner title="FHA Loans" headerFooter={true}
          isSWMC={isSWMC}
          setLoanPurpose={setLoanPurpose}
          setModalShow={setModalShow}
          setOfferLoanType={setOfferLoanType}></Banner>
      </StyledHero>
      <Container>
        <Row>
          <Col id="page-wrapper" className="pt-5">
            <h2>What is an FHA loan?</h2>
            <p>
              FHA loans are insured by the Federal Housing Administration, and
              can be a great option for people who would normally find it
              difficult to qualify for a mortgage due to their income level,
              credit history, or their ability to put down a sufficient amount
              of down payment. For borrowers with a credit score of 580 or
              higher, these loans allow a down payment of just 3.5% of the total
              cost of the home. For those with a credit score between 500 and
              579, a 10% down payment is required.
            </p>
            <h2>What are the benefits?</h2>
            <p>
              An FHA loan is one of the most attainable home loans on the
              market. Geared towards helping first-time home buyers, these loans
              offer affordable down payments and less-strict credit score
              requirements for those who qualify. Whether you&#39;re in the
              market for a new home or seeking financial assistance to help
              renovate your current one, FHA loans can offer funding for your
              residential goals.
            </p>
            <p>&nbsp;</p>
            <h2>What is required to receive an FHA loan?</h2>
            <p>
              Before applying for an FHA loan, it&#39;s important to know what
              to expect throughout the process. Once approved for an FHA loan,
              it is required to have an appraisal and credit report performed. A
              closing cost may also be required, depending on whether the home
              seller chooses to cover the closing costs.
            </p>
            <p>&nbsp;</p>
            <h2>Why should you choose Sun West?</h2>
            <p>
              Our team offers a 24-hour loan center for future home owners who
              don&#39;t have time to apply for a loan during regular business
              hours. We offer quick loan approvals and provide our clients with
              suitable loan options for their needs and financial situations.
              Our team members are well-versed in FHA loan rules and
              requirements and will ensure that you are knowledgeable about{" "}
              <Link to="../our-mortgage-process">our mortgage process</Link> prior
              to committing to a loan.
            </p>
            <p>
              For any questions about the FHA loan process or any of our other
              home loan services, give our team at Sun West a call to speak to a
              local team member who can answer all of your home loan questions.{" "}
              <Link to="../contact-us">Contact us today!</Link>
            </p>
          </Col>
        </Row>
      </Container>
      <div className="my-5 pb-2 px-0">
        <HowItWorks />
      </div>
      <FaqAccordion />
      <Col sm={12} className="mt-5 pt-5 px-0">
        <RequestCallback className="mt-5" />
      </Col>
      <Col sm={12}>
        <Subscribe />
      </Col>
    </div>
  );
}
