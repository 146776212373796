import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StyledHero from "../components/StyledHero";
import Banner from "../components/Banner";
import HowItWorks from "../components/HowItWorks";
import FaqAccordion from "../components/FaqAccordion";
import Subscribe from "../components/Subscribe";
import RequestCallback from "../components/RequestCallback";

import RenoImg from "../images/renovation-construction-loans-banner.jpg";

export default function Renovation({
  isSWMC,
  setLoanPurpose,
  setModalShow,
  setOfferLoanType,
}) {
  return (
    <div id="productLoans">
      <Helmet>
        <title>Renovation/Construction Loans | Sun West</title>
        <meta
          name="description"
          content="Need help making home renovations? There are many loan programs that can help you. One of them is the FHA 203k loans. Contact us to find out if you qualify."
        />
      </Helmet>
      <StyledHero img={RenoImg} bgPosition="center center">
        <Banner
          title="Renovation/Construction Loans"
          headerFooter={true}
          isSWMC={isSWMC}
          setLoanPurpose={setLoanPurpose}
          setModalShow={setModalShow}
          setOfferLoanType={setOfferLoanType}
        ></Banner>
      </StyledHero>
      <Container>
        <Row>
          <Col id="page-wrapper" className="pt-5">
            <h2>What is a Renovation and Construction Loan?</h2>
            <p>
              Designed for home rehabilitation purposes, FHA 203K loans can help
              pay for your home renovations. These loans are available both as a
              refinance and purchase. Thus, you can borrow against the equity in
              your home for home improvement and you can also get a single
              mortgage to finance both purchase and renovation of the home.
            </p>
            <p>&nbsp;</p>
            <h2>Eligibility</h2>
            <p>
              FHA 203K Loans can be a great option for home buyers who can meet
              these requirements:
            </p>
            <ol>
              <li>
                Be able to prove that money was spent on renovating your home
              </li>
              <li>Prove a decent credit score (580 or above)</li>
              <li>
                List of improvements (some are not eligible for financing such
                as swimming pools)
              </li>
            </ol>
            <p>
              If you meet these requirements, you may be able to qualify for an
              FHA 203K Loan.
            </p>
            <p>&nbsp;</p>

            <h2>Why should you choose Sun West?</h2>
            <p>
              Our team offers a 24-hour loan center for future home owners who
              don&#39;t have time to apply for a loan during regular business
              hours. We offer quick loan approvals and provide our clients with
              suitable loan options for their needs and financial situations.
              Our team members are well-versed in renovation/construction loan
              rules and requirements and will ensure that you are knowledgeable
              about{" "}
              <Link to="../our-mortgage-process">our mortgage process</Link>{" "}
              prior to committing to a loan.
            </p>
            <p>
              For any questions about the renovation/construction loan process
              or any of our other home loan services, give our team at Sun West
              a call to speak to a local team member who can answer all of your
              home loan questions.{" "}
              <Link to="../contact-us">Contact us today!</Link>
            </p>
          </Col>
        </Row>
      </Container>
      <div className="my-5 pb-2 px-0">
        <HowItWorks />
      </div>
      <FaqAccordion />
      <Col sm={12} className="mt-5 pt-5 px-0">
        <RequestCallback className="mt-5" />
      </Col>
      <Col sm={12}>
        <Subscribe />
      </Col>
    </div>
  );
}
