import React from 'react'
import Spinner from 'react-bootstrap/Spinner'


export const Loader = ({
    type
}) => <>
        {
            type === "textOnly" ?
                <>
                    <div className="">Loading...</div>
                </> :
                <>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </>
        }
</>