import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StyledHero from "../components/StyledHero";
import Banner from "../components/Banner";
import HowItWorks from "../components/HowItWorks";
import FaqAccordion from "../components/FaqAccordion";
import Subscribe from "../components/Subscribe";
import RequestCallback from "../components/RequestCallback";

import RefiImg from "../images/refinancing-banner-image.jpg";

export default function Refinancing({
  isSWMC,
  setLoanPurpose,
  setModalShow,
  setOfferLoanType,
}) {
  return (
    <div id="productLoans">
      <Helmet>
        <title>Refinancing | Sun West</title>
        <meta
          name="description"
          content="Need to replace you current loan? The process of refinancing a home means taking out a new loan to replace a current one. Contact us today to find out if you qualify!"
        />
      </Helmet>
      <StyledHero img={RefiImg} bgPosition="center center">
        <Banner
          title="Refinancing"
          headerFooter={true}
          isSWMC={isSWMC}
          setLoanPurpose={setLoanPurpose}
          setModalShow={setModalShow}
          setOfferLoanType={setOfferLoanType}
        ></Banner>
      </StyledHero>
      <Container>
        <Row>
          <Col id="page-wrapper" className="pt-5">
            <h2>What does it mean to refinance your home?</h2>
            <p>
            The process of refinancing a home means taking out a new loan to replace a current one. Homeowners who are currently paying off a home loan can apply and be approved for refinancing.
            </p>
            <h2 class="title-heading-left">
            What are the advantages of Refinancing?
            </h2>
            <p>By refinancing, you can access the equity of your home through a Cash-Out Refinance option. You can also refinance to obtain a new, lower interest rate through a Rate &amp; Term Refinance option. Refinancing also allows homeowners to pay off their mortgage more quickly if they desire to do so by changing their loan from a 30-year mortgage term to a 15-year mortgage term.</p>
            <h2 class="title-heading-left">Eligibility</h2>
            <p>We often recommend refinancing to our clients who have good credit scores. The higher the score, the likelier you will be approved for a new loan. Once you have been accepted for a new loan, try to avoid using your credit cards excessively to help prevent your credit score from changing while your application is being reviewed.</p>
            <h2>Why should you choose Sun West?</h2>
            <p>
            Our team offers a 24-hour loan center for future home owners who don&#39;t have time to apply for a loan during regular business hours. We offer quick loan approvals and provide our clients with suitable loan options for their needs and financial situations.
              Our team members are well-versed in refinancing rules and requirements and will ensure that you are knowledgeable about{" "}
              <Link to="../our-mortgage-process">our mortgage process</Link>{" "}
              prior to committing to a loan.
            </p>
            <p>
            For any questions about the refinancing process or any of our other home loan services, give our team at Sun West e a call to speak to a local team member who can answer all of your home loan questions. <Link to="../contact-us">Contact us today!</Link>
            </p>
          </Col>
        </Row>
      </Container>
      <div className="my-5 pb-2 px-0">
        <HowItWorks />
      </div>
      <FaqAccordion />
      <Col sm={12} className="mt-5 pt-5 px-0">
        <RequestCallback className="mt-5" />
      </Col>
      <Col sm={12}>
        <Subscribe />
      </Col>
    </div>
  );
}
