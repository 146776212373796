import React, { Suspense, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./Footer.css";
import SubFooter from "./SubFooter";
import styles from "../css/footerNew.module.css";
import { lazy } from "@loadable/component";

const Img = lazy(() => import("./Img"));

export default function Footer({ isSWMC, setLoanPurpose, setModalShow, setOfferLoanType }) {
  const ref = useRef();
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if(isSWMC) {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setIntersecting(entry.isIntersecting);
          observer.disconnect();
        }
      });
      observer.observe(ref.current);
      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect();
      };
    }
  }, []);
  let date = new Date();
  let currentYear = date.getFullYear();
  return isSWMC ? (
    <div className={styles.footerwrapper}>
      <div>
        <footer>
          <div className={styles.footerrow1}>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12} className={styles.desctypo}>
                <div className={styles.logosize}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Img>
                      <img
                        src="https://resources.swmc.com/swmc-images/Footer/logo_smls_header.png"
                        alt="SunWest Logo"
                        className={styles.footer_logo}
                      />
                    </Img>
                  </Suspense>
                </div>
                <br />
                <div className={styles.footer_desc_text}>
                  <p>
                    {" "}
                    <b>
                      {" "}
                      For licensing information, go to:{" "}
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            NMLS Consumer Access
                          </Tooltip>
                        }
                      >
                        <a
                          href="http://www.nmlsconsumeraccess.org/EntityDetails.aspx/company/3277"
                          target="_blank"
                          rel="noopener"
                        >
                          www.nmlsconsumeraccess.org
                        </a>
                      </OverlayTrigger>
                      .<br />
                      Visit{" "}
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Click here for full list of license information of
                            Sun West Mortgage Company, Inc
                          </Tooltip>
                        }
                      >
                        <a href="https://www.swmc.com/disclaimer">
                          https://www.swmc.com/disclaimer
                        </a>
                      </OverlayTrigger>{" "}
                      for the full list of license information.
                    </b>
                    <br />
                    <br />
                    Please{" "}
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-default">
                          View Texas Complaint Notice and Servicing Disclosure
                        </Tooltip>
                      }
                    >
                      <Link to="/TXdis">
                        <b>Click Here</b>
                      </Link>
                    </OverlayTrigger>{" "}
                    to view Texas Complaint Notice and Servicing Disclosure.
                  </p>
                </div>
              </Col>
              <Col
                md={{ span: 6, offset: 0 }}
                lg={6}
                sm={12}
                xs={12}
                className={styles.contactus}
              >
                <div className={styles.contact_block}>
                  <h4>Contact Us</h4>

                  <div className={styles.ul_footer}>
                    <div style={{ display: "flex" }}>
                      <Suspense fallback={<div>Loading...</div>}>
                        <Img>
                          <img
                            style={{
                              width: "15px",
                              height: "15px",
                              marginTop: "4px",
                            }}
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABUUlEQVRIia2UQS4EQRiFX4mFidmwwWQmjuAcboA7YGHhBNg4AMMcg0TsbGzbFhHsSRjSI6E/i66Jzqjqrprpl9Sm/qrvvf9PV0sVAhaBfSABPuxKgD1goep+FXzdAn3qA2vjwjeArAQ+VBZtYsfSD4AXO3GOa8rjsSWpGZGpKWkzpoMbR8ozoG3XuaOexBi4xtMu1DuO+ruL5RvRd3CaP/3EGDw59ro2eUdS11F/dIGmPQZ3klZG9lYlPXvOS9Kta9PXwWUJyKeL4JNAi7BHNlQGtKLiANcRBlc+jm9EknQakeck4mwuYBZ4C0j/CjR8HG8HxphPSb2ALMfGmDS6A0ki/y18laQfAEtjwQsmvRKDo4ng1mAZSB3wlPxVTy7g0GFwUAvcGswBLyNfznxtBtZkt2CwUyvcGjSAB+AemAm95/ub/pMxJgW2JWGMGYTe+wUdPGXWfGg3oQAAAABJRU5ErkJggg=="
                          />
                        </Img>
                      </Suspense>
                      <p>
                        <a
                          style={{ color: "#fff" }}
                          target="_blank"
                          href="http://maps.google.com/?q=6131 Orangethrope Ave Suite 500 Buena Park, CA 90620"
                          rel="noopener"
                        >
                          6131 Orangethorpe Avenue, Suite 500 Buena Park,
                          California 90620
                        </a>
                      </p>
                    </div>
                    <div className={styles.contactOptions}>
                      <div style={{ display: "flex" }}>
                        <Suspense fallback={<div>Loading...</div>}>
                          <Img>
                            <img
                              style={{
                                width: "15px",
                                height: "15px",
                                marginTop: "4px",
                              }}
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABDElEQVRIid3UvS4EURgG4DMbERKNUNFSyEai00o0VFuIK9AqRbIXsO5hoxKJlsQlKHRanYhOaCRWNTyalcyOPcvOnEK85TeZ98n5mQnh3wYN7OEKPVxiJiVw7HuOUpWvDCmHZ0yP09WIzNci87kQQisFsDDincUUQB6Zv4YQzlMA95HyVpZld+MAQ4Pl0uF+YL12cQm5LQHzVXpiWxTC4F5nIYStKkA0WEJeWMUNstTIWeks9lMDq3gvAG/Y7D+bwAZ2MFUH6ZZW0cMFngqza0xWBWbxGPk3FdOps4rd/lUdlRzNOkjnF6s4qAM0cPIDsF0Z6CMZ2gZv1le6tcpLUBOneMEDDqX+CP9MPgEbaMQ4wI+l/wAAAABJRU5ErkJggg=="
                            />
                          </Img>
                        </Suspense>
                        <p>
                          <a href="tel:+18004537884" style={{ color: "#fff" }}>
                            (800) 453-7884
                          </a>
                        </p>
                      </div>
                      <div className={styles.email}>
                        <Suspense fallback={<div>Loading...</div>}>
                          <Img>
                            <img
                              style={{
                                width: "15px",
                                height: "15px",
                                marginTop: "4px",
                              }}
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA40lEQVRIie2SMQrCMBiFX3QT6iA4CK1HcPEU4j28guABxNHVWzh4DCevEMHBycFN+Vz+QpXYNtjBoQ8CIXl53/8nkVr9jYA5cOZ3eWCW57oCwEtKG6rXO+fGnwBsepI0lDSKDL1IukqaSJJzzr3tFlocAAmwBR41ruRh3sTOUig2CDgAqa1NgWNJ+BGYmje1s5UAgDuwBLpAB1gAt4j9SkCowgzY28iqOqwLAHgCO6Bf8PaADSVvlHtDv+ibvKSVzdeSsjJz/otiAFHKAZ0mQ0NqAVGAc4O5PgRYNATxltXqT/QCASfIiQk2LYUAAAAASUVORK5CYII="
                            />
                          </Img>
                        </Suspense>
                        <p>
                          <a
                            href="mailto:inquiry@swmc.com"
                            style={{ color: "#fff" }}
                          >
                            inquiry@swmc.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className={styles.social_footer}>
                    <a
                      href="https://www.facebook.com/SunWestMortgage"
                      target="_blank"
                      rel="noopener"
                      title="Like us on Facebook!"
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                        <Img>
                          <img
                            style={{
                              height: "30px",
                              width: "30px",
                              margin: "10px",
                            }}
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAABmJLR0QA/wD/AP+gvaeTAAAEA0lEQVRogd2azWtcVRjGf881qG1taYugKIrxo+lCElFp09hFVBCFFF0ouLD9IxRBVBQXpeB30S7EjQi60ZUoglZtxLa2kbZxoVRpa1SwqE2NNv0yyePiztTJ5N6byb3nzFR/MMxkzjnv+z73Ts5533dGBMT2amAQ6ANWAdcAK4BLalNOAOPAGHAQGAV2SDoYMo5K2L7J9ou2f3Z5frL9gu2+TomQ7Q22d1cQkccu20O21S4xa2yPRBDSzB7bt8YUssj2K7an2yCmzrTtrbYvDi2mx/ZoG4U0s9/2DaHErLX9ewfF1Bm3vb6qmHtsn+ywkEYmbd9dFHPuTmK7H9gOLKl0VcJzCrhL0hdZg5mCbPcAXwLLIwZWhXGgX9L3zQNzBNleRCqmN2JAv5BmC8eB08BS4MLa8+XAZUAyj439wICk04WzbL8a6fN/1PZjbmG3sv1GizZfns/QGsc5Z950eudbYgGCpmzf3Lg2aTAiYBvz3+qFslXSJkmnAtsFuADY5oY0qTH4ISB0qrEHeDSwzWb6gXNbeaOgxyM4e1rS3xHsNvNk/UUXpCUAqdKQfAd81MpE28uZveNetEBfA7Z7JX1dv0ObFmigFbZLct6g7cW2X7c9Sbp9jzc8Hizhb2Oj8SrFWR4bC5xj+7XA/sYAEqdl85Ulrsh8HCkQswx4KLC/q21fn5D2AGIwUTA2CCyO4POOhLShEYM/C8auiOSzLwF6IhmfKRhbEclnTwJ0RzJeROhspE53AiyLZLwTLEv4twn4f2CpbJ8hrUXK8D75h/KEpMz/I6ddnKLs+0NgbYl4znYBJykv6Kyk4wtdVCvKcgsz29eVjOevBPij5OIo2F4JXFpy+URCwYneIVZXWHsoAeY0GjpMlXPxUAJ8FSqSQFS5QyNdwM4KBpbYvjZn7EdJU1kDTuuflTnrqqRiO+tfjYwFTuVt+6o8r7afiODviG0ltSLs3QpX5XzhHUmu51RvdTSUMLwNtSRR0j4gs1f8H2GHpAMwO+t9rkPBhOD5+otzgiS9R7Udr1N8LumD+h/NdcnDwHR746nENPBI4xuzBEnaC7zUzogq8qykWYlBVuX4FHCgPfFUYh/wTPObcwTVmur3Ab+1IaiyHAPul3SmeSCztpc0RipqMnJgZTgBbJCUWSXkNisk7QLuJS0AzxcmgSFJu/MmFHZfJH1C2hT8NXBgZTgKDEoaLpo0bztJ0giwjs6WGXuBdc07WhYt9cckHQZuI80mMkuCSEwBW4D1kn6I4sF2r+3hNpQPn9m+MYqInKDutP2p7ZmAgmZsf2z79rYJyQhule0ttr+pIOhb25sd4AdKQX9kZ7sbGABuATZLOpYz7wHSr0BHgeHauReEfwBdOEQgVhqkhAAAAABJRU5ErkJggg=="
                          />
                        </Img>
                      </Suspense>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/sun-west-mortgage-company/about/"
                      target="_blank"
                      rel="noopener"
                      title="Follow us on LinkedIn!"
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                        <Img>
                          <img
                            style={{
                              height: "30px",
                              width: "30px",
                              margin: "10px",
                            }}
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAABmJLR0QA/wD/AP+gvaeTAAACdElEQVRoge2Zz4tNYRjHP89NjGFGGKEs/MhisDMUhULKYiz8KJmVnR1ZkJ2tGv4AmRoLiylJspDsJI2EQvlRYyHJSGqSdOV+Le490+vOvee+rznd98ztfFbPec/zvD2fzj3vfc85UFDQVqx+QNJ84CgwCPQD3cAkMA6MmtnLtnY4GyT1S3qt5lQkXZFUit1rSyStkPQ5RcZlOHa/LZE07CkjSX8krY/dcyPcn86BwLrBjHvJBFeoN7C2L8tGssIVehtY+ybLRjJH0pGAe+iLpJ7YPbdE0oiHTFnSwdi9eiGpJOm0pMkmMs8k7YjdZxozdgoAkrqAXcBmYDHwFRg3sxdt7K0AnCskaQuw07OuDNw0sx+1WgMOA8s96yfM7EH9oKQ1wFpgJbAU+A58AF6ZWdlz7unJJgJWOUm66NTuD6yt1JpH0jpJl5S+h5ySdEPS1lYe7v9Q6DLc2yT2wYANkq4D74FzwKaU/B7gBPBE0mVJC5slzgtsJEvuAV2BNSXgLLBb0j4zm2qUEItQGZcB4I6qq/E/5P+5pjl7gAv1g3NZCOC8pI3uwFwXWgCccQfmuhDAkKTu5KAThJYAe5ODThAC2J4EMYUEjALbqN4LfcBx/u/BcSAJYgqdMrOTZvbUzMpm9s3MxqgKPgyca1USxBK6ZWZXG52obXiHgF8B8y1LglhCI2knzewjcD9gvkVJEEvonUdO6EsbIJ7Qb4+csOefGp2ybE9TCOWdQijvFEJ5pxDKO4VQ3imE8k5HCymwVk3i0NrZ5MzIdV/WXwOO4XfVfgK3nePHwCNgtUftc+CTR95d4BDVL4hpVIAxj/kKCgo8+AuAHwTPGUGp9QAAAABJRU5ErkJggg=="
                          />
                        </Img>
                      </Suspense>
                    </a>
                    <a
                      href=" https://www.youtube.com/user/SunWestMortgageCo"
                      target="_blank"
                      rel="noopener"
                      title="Subscribe on YouTube!"
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                        <Img>
                          <img
                            style={{
                              height: "30px",
                              width: "30px",
                              margin: "10px",
                            }}
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAABmJLR0QA/wD/AP+gvaeTAAAE8ElEQVRoge2aW4hVVRjHf5+NmZOXmebBxinygtGYiQQ92YOB+ZChXehGFgUSGGlPQXQPSbEHCUIsKKSyrBS6kA8ZCBY9pF2IciwzKnTKGrS8NOo4+O9hfbvZntnnnLXP2XOch/nD5qxvrW996/+dtda3LnvDCEYwgnpg5QoktQDLgAVAOzC6UaTK4DTwO7ANWG9mR7KUMh2SNAfYCkweMnr1oRtYaGbflhYMcsh7ZjfD15kE3cBMMzuazhyVobiM4e8MQAeB61nIcuj6HEa7gNv9+SGVv8rznsxhqxYsqKohabfi0S/pMq+3wvN6JI3xvHU5bNWC70v5Z/VQ2ciXgfMY6PbXgV7gNTM7pTAX781hqxYM4p/lUF4sldRsZv8Am4FXPP8+YBywgzAEVwOfp+qtBR4FfnV5o8s7C+A0AEldNXT9Uq97sf+OkrRP0roM++u9TqfLn7p8i8sv52i3q9R+ET0EsBzAzA66fAMwHXjGSb4laZOXPV1Qm5koyqHZkual5OXAITPrkTQWuAu4U9JYM/sLOFRQu4NQlEMAybCbQgj9xz0/HWSS9HGGCEU61Jb6NeD8CrqVyupC01AZBib5cDsJHCBsLk963iQG/sxeQiTcXkSjRfZQlu0VZnYGuBKY4+kHvexS11tEmGOXFNFokT3UJmk+MCOVt0rS5cDHAJIWAPd72VpJrUAL8Bjwqof9jnpIZO22u4DOeow2EHvMbGY6YyiH3DnBUASFPuBfT/cDx0rKx6faHUfBJ+G8Dp0G3gA+AX4hEO8lrCunzezvWkj4XBpNcLAZuBCYSjgeLKEepyvs5Y5Kmluz4dr5XOttR+3lsgyUc+ihBvAvx+nhoh06IWlcA7iX4zTeOVR1KDbKfWlmx934E5IOZzybq5BK9Noq6WXBzI4BX8XoxgaF71LpC4DWDJ1qPZjUyXMiLuVQdQ7H9tDBVPo54CLgcZffdfm2POxqwMHqKvEO/X/3ZWYnPDyf8Kw+l6+W9LOkDwAkdbhcepHRKultSV9IuinJlDRP0hZJuyRtlJ9oszhUQuyQK10cs9AMTAOStajJ5d4SvfeBZLuyQdJWwlDaTljPvgbuABZL6jSzA64b5VBsD52K1IvB84QzUx9hYzoduJswtx4xs3nABsKcXJSXw7nYy201s8PAby63ktphK+wa+l1sz2s8dsjVGpkqISHdlLL/kj9Z7UZxiHWoUYvqi8BnKXlPXg6xQ25CLKMUxlcpTwieIWx6AXaaWbJAzwem5OUQ69DECJ1k0k6WtAR4r4zes5LuYeDIfRj40dMzJDURdtgPcPZbkJYYorEOTYvQ2UNwqp1wHfxOGb1Wwj24EY4gPwEvePopwhKxCNgHbErVi+EQPYeuycjbBawBvoFwa6pwpzAX+BD4w+0nw2mN/64kODsTeNPM+oFuSbOBxYRhtg/4yMySg2I5DtVR4fgwuyaDBUDSVbHHhzzr0MoCOebF6pprqvILry3yF1yNgKR2SZsr8Bn0witrDv3JwF6rFLcCN0vaC+wFeghz5AghIPQS7hn6XP8IISyXIrk/SN8jjCFE0yZC4JgKzKLytfGgHXiWQ9uA6yoYGQVc4c+5xrbSjKyLxomE1/p13WA2APuBWVVf6/sXGgsJ3wEMV+wHbix1Bip/GjOBgU9jOhgen8Z0E+7J1/s9wwhGMIKC8R8C47SiUZAW/QAAAABJRU5ErkJggg=="
                          />
                        </Img>
                      </Suspense>
                    </a>
                    <a
                      href="https://www.instagram.com/sunwestmortgage_1980/"
                      target="_blank"
                      rel="noopener"
                      title="Follow us on Instagram!"
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                        <Img>
                          <img
                            style={{
                              height: "30px",
                              width: "30px",
                              margin: "10px",
                            }}
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAABmJLR0QA/wD/AP+gvaeTAAADwElEQVRoge2ZS4hcRRSGvzOKoG4ckcmMiTtFMBo1wYULF5oojGAcXbhRRCOCiiCabQhRMEQUFBQUISKaVRaComahSUx8ojEoQReKIBgnD8mMG5WETD4X9+r03HTfvnXv7Qxo/9A0darOqf+vrlfXgSGGGGKI/zKirqO6BLgeWAac3xKfP4CDwOcRcaSlmL2hhnqn+qk65+Awp36iTg1SzLK8kzONverStsWsVKcXQcw/mFavrcK17xpSLwG+BMabDkwiZsj4jeblQ8B1EfFr7Yhma2YxptnT6kj+2dxh39NoiMw2gDONY+pIB4cRdaajvnSjGCmrBNY3GpF2UFwW9Tip4w52ay7DZuen3JZC3Ul1rKr6TkF3AG/VGg0Q2Ae8AxwAjub2MWAFsBZYVdY/MJt/j3apm4qIt9MY6aM1R/cD9ZoK8a9Qt9fs45FeccvW0IVJIwDHgfsj4uaI+KZf44j4PiLuAh4ATiT2dVGvijJB/TaMThwHJiPi9QQfACLiNWCSNFE9uZ2dSqAHHoqI3UWjeh6wBriUbF39BHwYEX92touIXerDwNaW+JwOdVPF+fxeF99Q16uzXdrPqo+rp20I6o6KfW4alKBT6lVdxGyr4PtmUZR6dR6ztqCUddIN+yLiQMH2BHB3Bd97gMc6DRHxLbC/CaGmghacBWZrZkOC/0b13LKYqWgq6LtCeTVwQYL/KHBTn5hJaCpoulC+vEaMok8xZhKaCiruVNaIUfSp/c4BzQVdXCj/UCNG0WeiJheguaDlhfJO4PcE/xlgV8F2ZRNCTQXd3lnIbwBPJfg/GRF/lcVMRVNBq9QVBdsLwLYKvm8AL3YazG7plR5DeqGNTWHLAkOEwL1kB+xsF58ZsgP1vrxtJ56h4abQxuV0Ul2X35qBf0U9r75CdjZdllf9COzsMs1QHwRuaUqmrdv2y+rPEbFggefE3+3nrK4GXmqDSNmUO5UQ5xxgh7oulUD+y7yfx6iKntzKBB1L6AAyQlvN/oL3XdjqcnU78CppYgB+61VRNuXqXkHWAF+r+5l/JDmc140z/0iysmb8Um5lrz5jueNZDToeBOaAiYjo+iv1nHIRcRT4YlCsGuCzXmKg/zn0XMtk2sCzZZVVsg97gRtao9MMH0XEjWUNqgiaAL4C2k06peMwWTrlYFmjvlefiDgE3EaWn1ksTAO39hOTBHWpuqfCi0zb2K0W/3e1B3VK/dgsCzAonDTLra5N5dckrT/GfFp/CemnfREngCPAL2Rp/Z5b8xBDDDHE/xd/A8K8S3lRqiHrAAAAAElFTkSuQmCC"
                          />
                        </Img>
                      </Suspense>
                    </a>
                    <a
                      href="https://www.twitter.com/SunWestMortgage"
                      target="_blank"
                      rel="noopener"
                      title="Follow us on Twitter!"
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                        <Img>
                          <img
                            style={{
                              height: "30px",
                              width: "30px",
                              margin: "10px",
                            }}
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAABmJLR0QA/wD/AP+gvaeTAAAD7klEQVRoge2aW4hVVRyHv/80MgWWmE5MkhCmUsZgBCpJoVlZWWgv0UugVOSDET4EQUEXiuglsvKlCySUBdqDL/ZQVHS16CZKilggxVBmajVMONPMfD3sM8PxzDl7zmXtGYjzwYE5a63zX7/fWmvvdRto06ZNm/8zMd0C1EXABqAX6AFGgN+A/cCeiPh5GuXVj7pc/dB8RtW96hU5cS5Wt6lzq2XOVzcXbCTUx0ti6+VfdWtZjHPUG9Q31EF1Z63KnlNH1DsLNPRaA0Yq2am+o54oS/tTvaRWy/WVCp1R1xVg5qEWzFRj2FqNry6oKDykbkxo5tJSQ6ViSN1Uit2tblFXdZTVuaBCwwzgdfUxtYPWeQLoShAHYBB4GehVPwb6gFuAT8ZLqHfktMYH6rxma1fPVfsT9k4l36nnA5S3/N85mtYA+9WNajNz1ypgZhO/q4cDwK0R0Q9nGzoyyQ+7gR3APnVFg5Ve1mD5evkIWB0Rx8cSxg1FRB/wYx1BVgBflobhujp7rKdhqZOzG1gbEafLEysf9jcbCLgG2AscUZ9Wr8op+1cDcevlYEQMVyZWGtoOnK4sNAmLgEeB79Vj6lvqVvU6s5XHDOCX5jTnMsFMVdRNid9Ao2azeWoeqKa/o8xIF0BE7AC2pWjCEgHMShhvjKrDuHzI3aV+rt4NPAM8BViAkFQcq5bYWfb378DK0mfs+xDpZvfU/FQtcfyVq3aTbaxSLHOK5hQwNyImjKDyeegE8M1UqmqBL6qZgYm98eIUiEnBZ7Uyzprl1U7gK+DqohW1yNKIOFAtY8KyRV1CZqqoxWSrHI2IxbUyJ7wAIuIQcBswUKSqFtiVl1lzYWl20vI2sDS1ohYYARZGRNU5CHJe0RFxGFgG3Af8kF5bU+zJMwP5PbQBmA/0A7PJDgNXJ5XXGALLIuLbvEKdeZnAS+n0tMzuycxAfg91APuA5SlVNckZoDciJt2A5j1Do8D9wD8JhTXLk/WYqQuz06DBAvYz9fJ1acJPh3qjenwazJxUK88Lk5mao25XB6bIzLC6tlGdDZ+xqRcCN5PtmxYDNzUTZ7JqgM0R8WriuDk16jXqoQJ6ZlTdMpVGutXnza5dUjOo3jNVRhaqL1jcM3RSvb5VnXkT6wVk+6JrgdvJ1nVFbc/fB+6NiJbP70KdCTwIrC+lnQfMAybeWabnFPAI8EqtLXXTqEvUXQUNp0oG1GfV2UlN1DC20uwuM+Vt2xhH1YfVi4rSn/cMzSLbMqwn2zbMaSK+ZP9v8B7wLvBp8qFVQV0TotnK+3Ky3euVZPukHrJzhy6y1XB/6fMrcJjsvulgRPyRXnabNm2K4j90Lz45GkRkcwAAAABJRU5ErkJggg=="
                          />
                        </Img>
                      </Suspense>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <hr style={{ borderTop: "1px solid #fff" }} />
            <Row className={styles.awardrow}>
              <Col md={12}>
                <Row>
                  <Col xl={7} md={12}>
                    <Row>
                      <Col
                        md={3}
                        sm={6}
                        xs={6}
                        lg={3}
                        xl={3}
                        className={styles.topIconCol}
                      >
                        <Suspense fallback={<div>Loading...</div>}>
                          <Img>
                            <a>
                              <img
                                src="https://resources.swmc.com/swmc-images/minified/Top-50-Logo-blue.png"
                                className={styles.topIcons}
                                alt="Mortgage Executive Top 50 logo"
                                title="Mortgage Executive Top 50"
                              />
                            </a>
                          </Img>
                        </Suspense>
                      </Col>

                      <Col
                        md={6}
                        sm={6}
                        xs={6}
                        className={styles.bbbDiv + " " + "pl-0"}
                      >
                        <Row>
                          <Col md={6}>
                            <Suspense fallback={<div>Loading...</div>}>
                              <Img>
                                <a
                                  href="https://www.bbb.org/us/ca/buena-park/profile/mortgage-banker/sun-west-mortgage-company-inc-1126-172020499"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <img
                                    src="https://resources.swmc.com/swmc-images/minified/bbb-white.png"
                                    className={styles.bbbIcon}
                                    alt="bbb"
                                  />
                                </a>
                              </Img>
                            </Suspense>
                          </Col>

                          <Col md={6}>
                            <Suspense fallback={<div>Loading...</div>}>
                              <Img>
                                <a
                                  href="https://www.checkbca.org/report/sun-west-mortgage-company-inc-59000209"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <img
                                    src="https://resources.swmc.com/swmc-images/Footer/swmc/Bcawidgets.png"
                                    className={styles.memberIcon}
                                    alt="alliance"
                                  />
                                </a>
                              </Img>
                            </Suspense>
                          </Col>
                        </Row>

                        <Row
                          className={styles.tacaDiv + " " + "d-none d-md-flex"}
                        >
                          <Suspense fallback={<div>Loading...</div>}>
                            <Img>
                              <a
                                href="https://autismheroes.swmc.com/"
                                target="_blank"
                              >
                                <img
                                  src="https://resources.swmc.com/swmc-images/minified/TACA-LOGO.jpg"
                                  title="Autism Heroes"
                                  alt="Autism Heroes Logo"
                                  className={styles.Taca}
                                />
                              </a>
                            </Img>
                          </Suspense>
                        </Row>
                      </Col>

                      <Col md={3} sm={12} className={styles.ehoBirdeye}>
                        <Suspense fallback={<div>Loading...</div>}>
                          <Img>
                            <img
                              src="https://resources.swmc.com/swmc-images/Footer/equal-housing.png"
                              alt="SunWest Logo"
                              title="Equal Housing Opportunity"
                              className={styles.homicon + " " + "img-fluid"}
                            />
                          </Img>
                        </Suspense>
                        <div ref={ref} className={styles.birdeyeContainer}>
                          {isIntersecting ? (
                            <Suspense fallback={<div>Loading...</div>}>
                              <Img>
                                <a
                                  href="https://birdeye.com/sun-west-mortgage-company-154834253370951"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <iframe
                                    name="bfiframe"
                                    title="birdeye"
                                    className={styles.reviewIcon}
                                    src="https://birdeye.com/widget/render.php?bid=154834253370951&amp;wid=5&amp;ver=4&amp;update=0"
                                    id="bfpublish"
                                    scrolling="no"
                                    style={{
                                      borderWidth: "0px",
                                      display: "block",
                                      overflow: "hidden",
                                      frameborder: "0",
                                      height: "75px",
                                    }}
                                  />
                                </a>
                                <p
                                  style={{ textAlign: "center" }}
                                  className={styles.birdeye}
                                >
                                  Powered by{" "}
                                  <a
                                    href="https://birdeye.com/?utm_source=development.swmc.com&utm_medium=widget_review-badge&utm_campaign=birdeye_widget&utm_term=powered-by-birdeye&utm_content=customer-reviews_rectangle_#51a8d3"
                                    target="_blank"
                                    rel="noopener"
                                  >
                                    Birdeye
                                  </a>
                                </p>
                              </Img>
                            </Suspense>
                          ) : (
                            <div
                              style={{
                                width: "165px",
                                height: "113px",
                                display: "flex",
                                alignItems: "ceter",
                                textAlign: "center",
                              }}
                            >
                              Birdeye
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    xl={5}
                    md={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Row className={styles.tacaDiv + " " + "d-flex d-md-none"}>
                      <Suspense fallback={<div>Loading...</div>}>
                        <Img>
                          <a
                            href="https://swmc.com/autismheroes/"
                            target="_blank"
                          >
                            <img
                              src="https://resources.swmc.com/swmc-images/minified/TACA-LOGO.jpg"
                              style={{ maxWidth: "100%", width: "300px" }}
                              title="Autism Heroes"
                              alt="Autism Heroes Logo"
                              className={styles.Taca}
                            />
                          </a>
                        </Img>
                      </Suspense>
                    </Row>
                    <div className={styles.cellLogoDiv}>
                      <a href="https://celligence.com/" target="_blank">
                        <img
                          src="https://resources.swmc.com/swmc-images/minified/PoweredbyCelligenceWhite.png"
                          style={{
                            maxWidth: "100%",
                            width: "300px",
                            marginTop: "20px",
                            height: "59px",
                          }}
                          title="Powered by Celligence"
                          alt="Powered by Celligence logo"
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <div className={styles.footer_link_banner}>
            <Row className={styles.footcont}>
              <Col
                md={4}
                sm={12}
                className={styles.padleft0 + " " + styles.copyrighttxt}
              >
                <p className={styles.ul_footer + " " + styles.footer_list}>
                  © {currentYear} Sun West Mortgage Company, Inc. All Rights
                  Reserved
                </p>
              </Col>
              <Col
                md={8}
                sm={12}
                className={
                  styles.padleft0 +
                  " " +
                  styles.padright0 +
                  " " +
                  styles.dislinks
                }
              >
                <ul
                  className={
                    styles.ul_footer +
                    " " +
                    styles.footer_list +
                    " " +
                    styles.linklist
                  }
                >
                  <li>
                    <a
                      href="https://swmc.com/contact-us"
                      style={{ color: "#fff" }}
                      title="Contact SWMC"
                      target="_blank"
                    >
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://swmc.com/borrower-concerns-complaints"
                      style={{ color: "#fff" }}
                      target="_blank"
                      title="Borrower Concerns and Complaints Form"
                    >
                      Borrower Concerns & Complaints
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://swmc.com/privacy-policy"
                      style={{ color: "#fff" }}
                      title="Our Privacy Policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://econsent.swmc.com/"
                      target="_blank"
                      style={{ color: "#fff" }}
                      title="E-Sign Content"
                    >
                      E-Sign Consent
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://swmc.com/terms-and-conditions"
                      style={{ color: "#fff" }}
                      title="Our Terms & Conditions"
                      target="_blank"
                    >
                      Terms and Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://swmc.com/disclaimer"
                      style={{ color: "#fff" }}
                      title="List of license information of Sun West Mortgage Company, Inc."
                      target="_blank"
                    >
                      Licenses
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://swmc.com/swmc-site-map"
                      style={{ color: "#fff" }}
                      title="Our Site Map Page"
                      target="_blank"
                    >
                      Site Map
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </footer>
      </div>
    </div>
  ) : (
    <>
      <SubFooter
        setLoanPurpose={setLoanPurpose}
        setModalShow={setModalShow}
        setOfferLoanType={setOfferLoanType}
       />
      <Container>
        <footer className="text-center main-footer p-4">
          <Row className="justify-content-center">
            <Col md={12}>
              <p>
                &copy;{new Date().getFullYear()}&nbsp;Sun West Mortgage Company,
                Inc. All Rights Reserved <br />
                For licensing information, go to: <a href="http://www.nmlsconsumeraccess.org/EntityDetails.aspx/company/3277" title="NMLS Consumer Access" target="_blank" rel="noreferrer">www.nmlsconsumeraccess.org</a>.<br/>
                Visit <a href="https://www.swmc.com/disclaimer" title="Click Here for full list of license information of Sun West Mortgage Company, Inc." target="_blank" rel="noreferrer">www.swmc.com/disclaimer</a> for the full list of license information.
                <br/>Please&nbsp;
                <a
                  href="https://www.swmc.com/TXdis.php"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click&nbsp;Here
                </a>{" "}
                to view Texas Complaint Notice and Servicing Disclosure.
              </p>
              <p>
                Although Sun West Mortgage is approved to conduct business in
                the state of New York, this website has not yet been approved by
                the State of New York Department of Financial Services. For
                properties located in the State of New York, this website can
                not be used to upload an application, please visit{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.swmc.com"
                >
                  SWMC.com
                </a>{" "}
                to upload an application.
                <br />
                <span>
                  <a
                    href="https://www.swmc.com/privacy-policy.php"
                    target="_blank"
                    rel="noopener"
                  >
                    Privacy Policy
                  </a>{" "}
                  -{" "}
                  <a
                    href="https://www.swmc.com/terms-and-conditions.php"
                    target="_blank"
                    rel="noopener"
                  >
                    Terms and Conditions
                  </a>{" "}
                  -{" "}
                  <a
                    href="https://www.swmc.com/disclaimer.php"
                    target="_blank"
                    rel="noopener"
                  >
                    Disclaimers
                  </a>{" "}
                  - <Link to="sitemap">Sitemap</Link>
                </span>
              </p>
            </Col>
            <Col lg={4}>
              <img
                id="equalImg"
                alt="EHO logo"
                src="./assest/eho_blue.png"
                title="Equal Housing Opportunity"
                style={{ maxWidth: "100%x" }}
              />
            </Col>
          </Row>
        </footer>
      </Container>
    </>
  );
}
