import React from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments } from '@fortawesome/free-solid-svg-icons'
import CallBack from '../components/CallModule'
import styled from 'styled-components'

import "../css/requestCallback.css"

export default function RequestCallback({isSWMC}) {
    return (
        <StyleWrapper>
        <div className={"requestCallback-form-row" + ' ' + (isSWMC ? "isSWMC" : null)} id="requestCallbackWrapper">
            <Container>
                <Row className="text-center justify-content-center">
                    <div className="col-wrapper">
                        <Col lg={10} className="form-col py-3 mx-auto">
                            <div className="text-wrapper">
                                    <FontAwesomeIcon icon={faComments} className='react-icons' />
                                <h2 className="text-white pt-4">Want us to call you?</h2>
                                <p>Add your name and number below and a member of our team will call you.</p>
                            </div>
                            <CallBack isSWMC={isSWMC} />
                        </Col>
                    </div>
                </Row>
            </Container>
        </div>
        </StyleWrapper>
    )
}

const StyleWrapper = styled.div`

.requestCallback-form-row.isSWMC{

background: rgb(255, 255, 255) linear-gradient(90deg, rgba(0, 170, 228, 0.23) 0%, rgba(0, 170, 228, 0) 44.89%, rgba(0, 170, 228, 0.52) 99.06%) repeat scroll 0% 0% !important;
}

.requestCallback-form-row.isSWMC .form-col{

    background: rgb(255,255,255) linear-gradient(90deg,rgba(2, 191, 255, 0.07) 0%,rgba(0,170,228,0) 44.89%,rgba(0, 170, 228, 0.21) 99.06%) repeat scroll 0% 0% !important;
}

@media(max-width: 1000px){

.requestCallback-form-row.isSWMC .form-col{

    background: rgb(255,255,255) linear-gradient(90deg,rgba(2, 191, 255, 0.19) 0%,rgba(0,170,228,0) 44.89%,rgba(0, 170, 228, 0.41) 99.06%) repeat scroll 0% 0% !important;

}
}

@media(max-width: 768px){

    .requestCallback-form-row.isSWMC .form-col{

        background: rgb(255,255,255) linear-gradient(90deg,rgba(2, 191, 255, 0.24) 0%,rgba(0,170,228,0) 44.89%,rgba(0, 170, 228, 0.51) 99.06%) repeat scroll 0% 0% !important;

    }
    }



.isSWMC .text-wrapper {
    color: rgb(8, 63, 136) !important;
}

.isSWMC .text-wrapper h2 {
    color: rgb(8, 63, 136) !important;
}

.isSWMC .text-wrapper p {
    color: rgb(8, 63, 136) !important;
}

`
