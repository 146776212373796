import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import FastClosingIcon from "../images/fast-closing-image.png";
import AffordableIcon from "../images/AFFORDABLE-image.png";
import LaptopIcon from "../images/LENDER-FEES.png";

import "../css/guiding.css"

export default function Guiding({setLoanPurpose, setModalShow, setOfferLoanType}) {
  return (
    <Container fluid>
      <Row className="text-center my-4 guiding justify-content-around g-0">
        <Col sm={12} className="fl-heading">
          <h2 className="fl-heading-text">GUIDING YOU HOME</h2>
        </Col>
        <Col md={3} className="text-center box-1">
          <Image
            src={FastClosingIcon}
            fluid
            alt="Watch icon"
            className="fastClosing"
          />
          <h4 className="infobox-title">FAST CLOSINGS</h4>
          <p>
            Using our proprietary lending platform, we&#39;re able to get your
            loan closed fast and guide you home even faster.
          </p>
        </Col>
        <Col md={3} className="text-center box-2">
          <Image src={AffordableIcon} fluid alt="Dollar" />
          <h4 className="infobox-title">AFFORDABLE DOWN PAYMENTS</h4>
          <p>
            Put down as little as 3% on fixed-rate products and ask about
            lender-paid mortgage insurance options.
          </p>
        </Col>
        <Col md={3} className="text-center box-3">
          <Image src={LaptopIcon} fluid alt="Laptop icon" />
          <h4 className="infobox-title">NO HIDDEN LENDER FEES</h4>
          <p>
            We don&#39;t hide anything in the fine print. It&#39;s our priority
            to keep you informed of your financial obligations.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="fl-col-content fl-node-content">
            <div
              id="apply-btn-styl"
              className="fl-module fl-module-pp-smart-button fl-node-5bcdaff66b0a3 apply_today_btn"
              data-node="5bcdaff66b0a3">
              <div className="fl-module-content fl-node-content">
                <div className="pp-button-wrap pp-button-width-auto pp-button-center">
                <button className="pp-button" onClick={() => {
                  setLoanPurpose('');
                  setModalShow(true);
                  setOfferLoanType('');
                }}>
                  <span className="pp-button-text">APPLY TODAY</span>
                </button>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
