import styled from "styled-components";

const StyledHero = styled.header`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${(props) => props.img});
  background-repeat: no-repeat;
  background-position: ${(props) => props.bgPosition};
  background-attachment: fixed;
  background-size: cover;

  // @media (max-width: 1992.99px) {
  //   min-height: 40vh;
  // }

  // @media (max-width: 1600.99px) {
  //   min-height: 50vh;
  // }

  // @media (max-width: 991.99px) {
  //   margin-top:160px;
  // }

  // @media (max-width: 768px) {
  //   margin-top:75px;
  // }

  // @media (max-width: 581.99px) {
  //     min-height: 30vh;
  //   }
`;

export default StyledHero;
